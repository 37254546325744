import React from "react";
// import "../App.css";
import { styles } from "./readMore.style";

const ReadMore = ({ children, limit = 15000 }) => {
  const text = children;
  
  const createMarkup = (htmlContent) => {
    return { __html: htmlContent };
  };

  return (
    <p style={styles.text} dangerouslySetInnerHTML={createMarkup(text)}>
    </p>
  );
};

export default ReadMore;
