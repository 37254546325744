import React from "react";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";
import TitleModal from "./components/Title.modal";

const SpecsBodyMobile = ({ data, title, icon, type }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-4 interactive-title">
        <span className="me-2">{icon}</span>
        {title ? <TitleModal title={title} /> : <h6>{type}</h6>}
      </div>
      <div
        className="col-12 col-lg-6 h-lg-100"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6 py-3 ">
        <div style={{ height: "calc(100vh - 332px)", overflow: "auto" }}>
          <div className="py-3">
            <ListModal list={data.specifiche} />
          </div>
        </div>
      </div>
    </>
  );
};

export default SpecsBodyMobile;
