import React, { Component } from "react";

// import { Textfit } from "react-textfit";
// import { isMobile } from "react-device-detect";
const img_custom = require("../../assets/images/prodotto.png");

class MyGroceryElement extends Component {
  render() {
    return (
      <div className="container my-3">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-5 col-md-4">
                <img
                  src={process.env.PUBLIC_URL + this.props.info.image_file}
                  alt="img"
                  style={{ width: "100%", aspectRatio: 1 }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = img_custom;
                  }}
                />
              </div>
              <div className="col-7 col-md-4 d-flex flex-column justify-content-center">
                <p className="my-grocery-element-field1">{this.props.info.field1}</p>
                <p className="my-grocery-element-fields">
                  {this.props.info.field2} {this.props.info.field3}{" "}
                  {this.props.info.field4}
                </p>
                <p className="d-md-none my-grocery-element-price">
                  {this.props.info.currentPrice > 0 &&
                    this.props.info.currentPrice + "€"}
                </p>
                <p className="my-grocery-element-qta mt-1 mt-md-3">
                  {this.props.info.quantity} {this.props.info.grocery_label}
                </p>
              </div>
              <div className="col-3 d-none d-md-flex align-items-center justify-content-center">
                <p className="my-grocery-element-price">
                  {this.props.info.currentPrice > 0 &&
                    this.props.info.currentPrice + "€"}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// const fieldsStyle = {
//   display: "flex",
//   alignItems: "center",
// };

// const centerStyle = {
//   display: "flex",
//   justifyContent: "center",
//   alignItems: "center",
//   paddingLeft: "0",
//   paddingRight: "0",
// };

export default MyGroceryElement;
