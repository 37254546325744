import React, { useRef } from "react";
import { connect } from "react-redux";
import { styles } from "./zoomButton.style";
// import { withRouter } from "react-router-dom";
// import { ZoomIn, ZoomOut } from "../../assets/images/icone";
import Slider from "react-input-slider";

const ZoomButton = ({ carouselIndex, history }) => {
  const prevZoomValue = useRef(carouselIndex.zoomValue);

  const zoomIn = () => {
    carouselIndex.refZoom.current.zoomIn(1);
  };
  const zoomOut = () => {
    carouselIndex.refZoom.current.zoomOut(1);
  };

  const handleChange = ({ x }) => {
    if (x > prevZoomValue.current) {
      carouselIndex.refZoom.current.zoomIn(x - prevZoomValue.current);
    } else if (x < prevZoomValue.current) {
      carouselIndex.refZoom.current.zoomOut(prevZoomValue.current - x);
    }
    prevZoomValue.current = x;
  };

  return (
    <div id="zoom-btns" style={styles.buttons_wrapper}>
      {/* <div style={{ textAlign: "right", display: "flex" }}> */}
      <div
        className="carousel-pagination-button"
        disabled={carouselIndex.zoomValue === 1}
        onClick={zoomOut}
        style={styles.button}
      >
        <i className="fas fa-minus"></i>
        {/* <ZoomOut style={{ width: "35px" }} /> */}
      </div>
      <Slider
        className="ZoomSlider"
        axis="x"
        x={carouselIndex.zoomValue}
        xmin={1}
        xmax={5}
        onChange={handleChange}
      />
      <div
        className="carousel-pagination-button"
        disabled={carouselIndex.zoomValue === 5}
        onClick={zoomIn}
        style={styles.button}
      >
        <i className="fas fa-plus"></i>
        {/* <ZoomIn style={{ width: "35px" }} /> */}
      </div>
      {/* </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel
  };
};

export default connect(mapStateToProps)(ZoomButton);
