import * as React from "react";
const LogoutIcona = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Livello_2"
    data-name="Livello 2"
    viewBox="0 0 85.04 85.04"
    {...props}
  >
    <g id="Livello_2-2" data-name="Livello 2">
      <path
        d="M42.35 78.31c-16.27 0-29.51-13.24-29.51-29.51 0-6.51 2.08-12.68 6-17.84a1.927 1.927 0 0 1 3.07 2.33 25.438 25.438 0 0 0-5.22 15.51c0 14.15 11.51 25.66 25.66 25.66S68.01 62.95 68.01 48.8c0-5.66-1.8-11.02-5.22-15.51a1.927 1.927 0 0 1 3.07-2.33c3.93 5.17 6 11.34 6 17.84 0 16.27-13.24 29.51-29.51 29.51Z"
        className="cls-1 cls-custom"
      />
      <path
        d="M42.35 50.58c-1.06 0-1.93-.86-1.93-1.93V10.91a1.931 1.931 0 0 1 3.86 0v37.75c0 1.06-.86 1.93-1.93 1.93Z"
        className="cls-1 cls-custom"
      />
      <path
        d="m26.6 23.67 2.82 2.62 12.93-13.91 12.93 13.91 2.82-2.62L42.35 6.73 26.6 23.67z"
        className="cls-1 cls-custom"
      />
      <path
        d="M0 0h85.04v85.04H0z"
        style={{
          fill: "none"
        }}
      />
    </g>
  </svg>
);
export default LogoutIcona;
