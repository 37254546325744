import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const WorldBodyMobile = ({ data, title, icon, modalRedux }) => {
  const images = modalRedux?.contentModal?.images;

  const uncroppedImage = images?.find((image) => !image.cropped);
  const croppedImage = images?.find((image) => image.cropped);
  const selectedImage = uncroppedImage || croppedImage;

  //Modifica effettuata per retrocompatibilità della funzionalità
  let interactivityImage = [];
  let interactivityLink = null;

  if (typeof data == "string") {
    interactivityLink = data;
  } else {
    interactivityImage = data.img;
    interactivityLink = data.link;
  }

  return (
    <div>
      {title && (
        <div className="d-flex align-items-center mb-4 interactive-title">
          <span>{icon}</span>
          <h6 className="mb-0 ms-2">{title}</h6>
        </div>
      )}
      <img
        src={interactivityImage.length > 0 ? interactivityImage : selectedImage?.image_file}
        className="w-100 mb-5"
      />
      <Link to={interactivityLink}>vai</Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(WorldBodyMobile);
