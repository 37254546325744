import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input, InputGroup, Button, Collapse } from "reactstrap";
import { Search } from "../assets/images/icone";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "./storemap.css";
import { serverResponse } from "../util/fakeServer";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import * as services from "../services/storemap.services";
import StoreOpenIcon from "./../assets/icons/storeOpen.icon";
import NavigaIcon from "./../assets/icons/naviga.icon";
import Loader from "../components/common/Loader";
import StoreCloseIcon from "../assets/icons/storeClose.icon";
import { Link } from "react-router-dom";
import ArrowIcon from "../assets/icons/arrow.icon";
import { BREAKPOINT } from "../helper/constant";
import useCurrentWidth from "../hooks/widthHook";
import { debounce } from "lodash";
import BottomSheet from "react-swipeable-bottom-sheet";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const StoreMap = () => {
  const [loading, setLoading] = useState(true);
  const [marketData, setMarketData] = useState([]);
  const [pinMapData, setPinMapData] = useState([]);
  const [detailData, setDetailData] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [storeIdToFind, setStoreIdToFind] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermMobile, setSearchTermMobile] = useState("");
  const [mapCenter, setMapCenter] = useState(null);
  const [openStore, setOpenStore] = useState(null);
  const [detailCache, setDetailCache] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [show, setShow] = useState(false);
  const markerRefs = useRef([]);
  const storeRefs = useRef({});
  const initialLocationSet = useRef(false);
  const [currentPage, setCurrentPage] = useState(1);
  const currentPageRef = useRef(currentPage);
  const containerRef = useRef(null);
  const containerRefMobile = useRef(null);
  const storeRefsMobile = useRef({});
  const [hasMore, setHasMore] = useState(true);
  const [totalPages, setTotalPages] = useState(0);

  const width = useCurrentWidth();

  const currentDay = new Date().getDay();

  const toggleDrawer = () => {
    setShow((prev) => !prev);
  };

  const getMarket = useCallback((page, storeIdToFind) => {
    setLoading(true);
    const requestData = {
      all: false,
      page: page,
      pageSize: 10,
    };
    services
      .getMarket(requestData)
      .then((res) => {
        let newMarketData = [];

        if (res.status === 200 && res.data && res.data.data) {
          newMarketData = Array.isArray(res.data.data.markets)
            ? res.data.data.markets
            : [];
          setTotalPages(res.data.data.totalPages || 0);

          if (page === 1) {
            setMarketData(newMarketData);
          } else {
            setMarketData((prevData) => [...prevData, ...newMarketData]);
          }
          setHasMore(res.data.data.totalPages > page);
          if (
            storeIdToFind &&
            newMarketData.some((store) => store.id === storeIdToFind)
          ) {
            setStoreIdToFind(null);
          }
        } else {
          setMarketData([]);
        }
      })
      .catch((err) => {
        console.log("Error fetching market data:", err);
        setMarketData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getSearch = useCallback((value, page) => {
    const requestData = {
      all: false,
      page: page,
      pageSize: 10,
      string: value,
    };
    services
      .getSearch(requestData)
      .then((res) => {
        if (res.status === 200) {
          setTotalPages(res.data.data.totalPages);
          if (page === 1) {
            setSearchData(res.data.data.markets);
          } else {
            setSearchData((prevData) => [
              ...prevData,
              ...res.data.data.markets,
            ]);
          }
          setHasMore(res.data.data.totalPages > page);
          setIsSearchActive(true);
          if (res.data.data.markets.length > 0) {
            const firstMarket = res.data.data.markets[0];
            setMapCenter([firstMarket.latitudine, firstMarket.longitudine]);
            if (markerRefs.current[firstMarket.id]) {
              markerRefs.current[firstMarket.id].openPopup();
            }
          }
        }
      })
      .catch((err) => {
        console.log("this is error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    currentPageRef.current = currentPage;
  }, [currentPage]);

  const handleScroll = useCallback(() => {
    const conRef = width < BREAKPOINT.xs ? containerRefMobile : containerRef;
    if (
      conRef.current &&
      !loading &&
      hasMore &&
      currentPageRef.current < totalPages
    ) {
      const { scrollTop, scrollHeight, clientHeight } = conRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setCurrentPage((prevPage) => {
          if (prevPage < totalPages) {
            return prevPage + 1;
          } else {
            return prevPage;
          }
        });
      }
    }
  }, [loading, hasMore, totalPages, width]);

  useEffect(() => {
    if (totalPages && currentPage <= totalPages && currentPage > 1) {
      if (searchTerm.length > 2) {
        getSearch(searchTerm, currentPage);
      } else {
        getMarket(currentPage, storeIdToFind);
      }
    }
  }, [currentPage, getMarket, totalPages]);

  useEffect(() => {
    if (currentPage === 1) {
      getMarket(1);
    }
  }, [currentPage, getMarket]);

  useEffect(() => {
    const conRef = width < BREAKPOINT.xs ? containerRefMobile : containerRef;
    setTimeout(() => {
      const currentContainer = conRef.current;
      if (currentContainer) {
        currentContainer.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (currentContainer) {
          currentContainer.removeEventListener("scroll", handleScroll);
        }
      };
    }, 500);
  }, [handleScroll, width]);

  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value.length > 2) {
        setCurrentPage(1);
        getSearch(value, 1);
      } else {
        setIsSearchActive(false);
        setSearchData([]);
      }
    }, 800),
    []
  );

  const handleSearchChange = (event) => {
    const value = event.target.value.trim();
    setSearchTerm(value);

    if (value === "") {
      setIsSearchActive(false);
      setSearchData([]);
      setCurrentPage(1);
      getMarket(1);
      debouncedSearch.cancel();
    } else if (value.length > 2) {
      debouncedSearch(value);
    } else {
      setIsSearchActive(false);
      setSearchData([]);
    }
  };

  const handleSearchMobile = (event) => {
    const value = event.target.value.trim();
    setSearchTermMobile(value);

    if (value === "" && searchTermMobile !== "") {
      setIsSearchActive(false);
      setCurrentPage(1);
      getMarket(1);
      setSearchData([]);
      debouncedSearch.cancel();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      const value = event.target.value.trim();
      if (value) {
        event.target.blur();
        setCurrentPage(1);
        getSearch(value, 1);
      }
    }
  };

  useEffect(() => {
    const setMapToFirstStore = () => {
      if (marketData.length > 0) {
        const firstMarket = marketData[0];
        setMapCenter([
          parseFloat(firstMarket.latitudine),
          parseFloat(firstMarket.longitudine),
        ]);
      }
    };

    if (!initialLocationSet.current) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setMapCenter([position.coords.latitude, position.coords.longitude]);
          initialLocationSet.current = true;
        },
        (error) => {
          setMapToFirstStore();
          initialLocationSet.current = true;
        }
      );
    } else {
      if (!mapCenter && marketData.length > 0) {
        setMapToFirstStore();
      }
    }
  }, [marketData]);

  const getPinMap = useCallback(() => {
    services
      .getPinMap()
      .then((res) => {
        if (res.status === 200) {
          const parsedData = res.data.data.map((store) => ({
            ...store,
            position: [
              parseFloat(store.latitudine),
              parseFloat(store.longitudine),
            ],
          }));
          setPinMapData(parsedData);
        }
      })
      .catch((err) => {
        console.log("this is error", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getDetail = useCallback(
    (id_market) => {
      if (detailCache[id_market]) {
        setDetailData(detailCache[id_market]);
        handleDataLoaded(id_market);
        return;
      }
      const requestData = { id_market };
      setLoading(true);
      services
        .getDetail(requestData)
        .then((res) => {
          if (res.status === 200) {
            setDetailData(res.data.data);
            setDetailCache((prevCache) => ({
              ...prevCache,
              [id_market]: res.data.data,
            }));
            handleDataLoaded(id_market);
          }
        })
        .catch((err) => console.log("this is error", err))
        .finally(() => setLoading(false));
    },
    [detailCache]
  );

  useEffect(() => {
    getPinMap();
  }, [getPinMap]);

  const scrollToStore = (id) => {
    const storeElement = storeRefs.current[id];
    const container = containerRef.current;

    if (storeElement && container) {
      const containerRect = container.getBoundingClientRect();
      const storeRect = storeElement.getBoundingClientRect();
      const scrollTop = container.scrollTop + storeRect.top - containerRect.top;

      container.scrollTo({
        top: scrollTop,
        behavior: "smooth",
      });
    }
  };

  const handleDataLoaded = (id) => {
    setTimeout(() => {
      scrollToStore(id);
    }, 500);
  };

  const handleStoreClickMobile = (id) => {
    const isStoreLoaded = marketData.some((store) => store.id === id);
    if (!isStoreLoaded) {
      setStoreIdToFind(id);
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
      const selectedPin = pinMapData.find((pin) => pin.id === id);
      if (selectedPin) {
        setMapCenter(selectedPin.position);
        markerRefs.current[id]?.openPopup();
        if (openStore !== id) {
          setOpenStore(id);
          setShowDetail(true);
          setDetailData(null);
          getDetail(id);
        }
      }
    } else {
      const selectedPin = pinMapData.find((pin) => pin.id === id);
      if (selectedPin) {
        setMapCenter(selectedPin.position);
        markerRefs.current[id]?.openPopup();
        if (openStore !== id) {
          setOpenStore(id);
          setShowDetail(true);
          setDetailData(null);
          getDetail(id);
        }
      }
    }
  };

  const handleStoreClick = (id) => {
    const isStoreLoaded = isSearchActive
      ? searchData
      : marketData.some((store) => store.id === id);
    if (!isStoreLoaded) {
      setStoreIdToFind(id);
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1);
      }
      const selectedPin = pinMapData.find((pin) => pin.id === id);
      if (selectedPin) {
        setMapCenter(selectedPin.position);
        markerRefs.current[id]?.openPopup();
        if (openStore !== id) {
          setOpenStore(id);
          setDetailData(null);
          getDetail(id);
        } else {
          scrollToStore(id);
        }
      }
    } else {
      const selectedPin = pinMapData.find((pin) => pin.id === id);
      if (selectedPin) {
        setMapCenter(selectedPin.position);
        markerRefs.current[id]?.openPopup();
        if (openStore !== id) {
          setOpenStore(id);
          setDetailData(null);
          getDetail(id);
        } else {
          scrollToStore(id);
        }
      }
    }
  };

  const getCurrentDayOpeningStatus = (openings) => {
    const currentDay = new Date().getDay();
    const todayOpening = openings?.find(
      (o) => o.giorno_id === currentDay.toString()
    );
    if (!todayOpening) return { isOpen: false };

    const timeRanges = todayOpening.orario.split(" / ");
    const currentTime = new Date();
    let isOpen = false;
    let closingTime = "";

    timeRanges.forEach((range) => {
      const [start, end] = range.split(" - ").map((time) => {
        const [hours, minutes] = time.split(":");
        const date = new Date(currentTime);
        date.setHours(hours, minutes, 0);
        return date;
      });

      if (currentTime >= start && currentTime <= end) {
        isOpen = true;
        closingTime =
          end.getHours() +
          ":" +
          (end.getMinutes() < 10 ? "0" : "") +
          end.getMinutes();
      }
    });

    return { isOpen, closingTime };
  };

  const renderStoreOpenStatus = (storeDetails) => {
    if (!storeDetails || !storeDetails.aperture) {
      return;
    }

    const { isOpen, closingTime } = getCurrentDayOpeningStatus(
      storeDetails?.aperture
    );

    if (isOpen) {
      return (
        <div>
          <StoreOpenIcon />
          <span style={{ marginLeft: "10px" }}>
            aperto (chiude alle {closingTime})
          </span>
        </div>
      );
    } else {
      return (
        <div>
          <StoreCloseIcon />
          <span style={{ marginLeft: "10px" }}>chiuso</span>
        </div>
      );
    }
  };

  const MapUpdater = () => {
    const map = useMap();
    useEffect(() => {
      if (mapCenter) {
        map.setView(mapCenter, map.getZoom());
      }
    }, [map]);
    return null;
  };

  const handleTouchStart = (e) => {
    e.stopPropagation();
  };

  if (!mapCenter) {
    return <Loader background={"transparent"} />;
  }

  return (
    <div
      className={`storemap_block ${show && "showList"} ${
        showDetail && "showDetail"
      }`}
    >
      <div className="store_search_box_container search_box_desktop  store-result-container px-0">
        <div className="search_bar">
          <div
            className="theme-brand text-center"
            style={{ textAlign: "center" }}
          >
            <img
              className="img-fluid"
              style={{
                width: "100px",
                marginBottom: "1rem",
              }}
              src={serverResponse.config.logo_full}
              alt="Search Page Logo"
            />
          </div>
          <InputGroup className="custom-search-bar-wrapper">
            <Input
              type="text"
              name="search"
              id="search"
              placeholder="cerca uno store"
              onChange={handleSearchChange}
            />
            <Search />
          </InputGroup>
        </div>
        <div
          className="store-result-box search-result-box"
          ref={containerRef}
          style={{ maxHeight: "800px", overflowY: "auto" }}
        >
          {!loading && marketData?.length === 0 && (
            <div className="no-stores-found">Nessun punto vendita trovato</div>
          )}
          {(isSearchActive ? searchData : marketData).map((store) => (
            <div
              key={store?.id}
              className="store-result"
              ref={(el) => (storeRefs.current[store.id] = el)}
            >
              <div
                className="store-header"
                onClick={() => {
                  if (openStore === store?.id) {
                    setOpenStore(null);
                    setDetailData(null);
                  } else {
                    handleStoreClick(store?.id);
                  }
                }}
              >
                <img
                  src={serverResponse.config.clientIcon}
                  alt="Store Thumbnail"
                  className="store-thumbnail"
                />
                <div className="store-info">
                  <h5 className="store-name">{store?.nome}</h5>
                  <p className="store-address">{store?.indirizzo}</p>
                  <p className="store-code">
                    {store?.cap} - {store?.citta}
                  </p>
                </div>
                <button className="accordion-toggle">
                  {openStore === store?.id ? <FaAngleUp /> : <FaAngleDown />}
                </button>
              </div>
              <Collapse
                className="store-Collapse"
                isOpen={openStore === store?.id}
              >
                <div className="store-info">
                  <div className="store-open">
                    {detailData && renderStoreOpenStatus(detailData)}
                  </div>
                  <div className="store-naviga">
                    <Button
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${detailData?.latitudine},${detailData?.longitudine}`,
                          "_blank"
                        )
                      }
                      style={{ width: "100%" }}
                    >
                      naviga
                      <NavigaIcon />
                    </Button>
                  </div>
                  {loading && <span className="loadingText">Loading....</span>}
                  {detailData?.reparti?.length > 0 && (
                    <div className="store-reparti">
                      <h6 className="storebar-title">Reparti</h6>
                      <div className="store-repart-box">
                        {detailData?.reparti?.map((reparti) => (
                          <div
                            className="store-repart-box__inner"
                            key={reparti?.nome}
                          >
                            <img
                              src={reparti?.file}
                              title={reparti?.nome}
                              alt=""
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {detailData?.servizi?.length > 0 && (
                    <div className="store-servizi">
                      <h6 className="storebar-title">Servizi disponibili</h6>
                      <div className="store-servizi-box">
                        {detailData?.servizi?.map((servizi) => (
                          <div
                            className="store-servizi-box__inner"
                            key={servizi?.nome}
                          >
                            <img src={servizi?.file} alt="" />
                            <span>{servizi?.nome}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                  {detailData?.volantini?.length > 0 && (
                    <div className="store-volantini">
                      <h6 className="storebar-title">Offerte disponibili</h6>
                      <div className="store-volantini-box">
                        {detailData?.volantini
                          ?.slice(0, 2)
                          .map((volantini, index) => (
                            <div
                              className="store-volantini-box__inner"
                              key={index}
                            >
                              <img src={volantini?.image} alt="" />
                              <div className="store-volantini-content">
                                <h3>{volantini?.name}</h3>
                                <p>
                                  {volantini?.selloutStart}{" "}
                                  {volantini?.selloutEnd}
                                </p>
                                <Link
                                  className="store-volantini-btn"
                                  to={volantini?.url}
                                >
                                  sfoglia <ArrowIcon />
                                </Link>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                  {detailData?.aperture?.length > 0 && (
                    <div className="store-aperture">
                      <h6 className="storebar-title">Orari di apertura</h6>
                      <ul>
                        {detailData?.aperture?.map((day, index) => (
                          <li
                            key={index}
                            style={
                              day.giorno_id === currentDay.toString()
                                ? { fontWeight: "bold" }
                                : {}
                            }
                          >
                            <span> {day?.giorno_nome} </span>
                            <span> {day?.orario} </span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              </Collapse>
            </div>
          ))}
          {loading && <span className="loadingText">Loading....</span>}
        </div>
      </div>

      <BottomSheet
        open={show}
        onChange={setShow}
        data-body-scroll-lock-ignore
        style={{ bottom: "137px", zIndex: "9999" }}
        overlay={false}
        swipeableViewsProps={{
          disabled: showDetail,
        }}
      >
        <div className="mobile_searchbar">
          <p className="scrollbaar" onClick={toggleDrawer}></p>

          <div className="search_bar">
            <InputGroup className="custom-search-bar-wrapper">
              <Input
                type="text"
                name="search"
                id="search"
                placeholder="cerca uno store"
                value={searchTermMobile}
                onChange={handleSearchMobile}
                onKeyDown={handleKeyDown}
              />
              <Search />
            </InputGroup>
          </div>
        </div>
        <div className="store_search_box_container store-result-container px-0">
          <div
            className="store-result-box search-result-box"
            ref={containerRefMobile}
            style={{ maxHeight: "800px", overflowY: "auto" }}
            onTouchStart={handleTouchStart}
          >
            {!loading && marketData?.length === 0 && (
              <div className="no-stores-found">
                Nessun punto vendita trovato
              </div>
            )}
            {(isSearchActive ? searchData : marketData)
              ?.filter((store) => openStore === null || store?.id === openStore)
              .map((store) => (
                <div
                  key={store?.id}
                  className="store-result"
                  ref={(el) => (storeRefsMobile.current[store.id] = el)}
                >
                  <div
                    className="store-header"
                    onClick={() => {
                      handleStoreClickMobile(store?.id);
                    }}
                  >
                    <img
                      src={serverResponse.config.clientIcon}
                      alt="Store Thumbnail"
                      className="store-thumbnail"
                    />
                    <div className="store-info">
                      <h5 className="store-name">{store?.nome}</h5>
                      <p className="store-address">{store?.indirizzo}</p>
                      <p className="store-code">
                        {store?.cap} - {store?.citta}
                      </p>
                    </div>
                    <button className="accordion-toggle">
                      {openStore === store?.id ? (
                        <IoCloseOutline
                          onClick={(e) => {
                            e.stopPropagation();
                            setOpenStore(null);
                            setShowDetail(false);
                          }}
                        />
                      ) : (
                        <FaAngleDown />
                      )}
                    </button>
                  </div>
                  <Collapse
                    className="store-Collapse"
                    isOpen={openStore === store?.id}
                  >
                    <div className="store-info">
                      <div className="store-open">
                        {detailData && renderStoreOpenStatus(detailData)}
                      </div>
                      <div className="store-naviga">
                        <Button
                          onClick={() =>
                            window.open(
                              `https://www.google.com/maps/dir/?api=1&destination=${detailData?.latitudine},${detailData?.longitudine}`,
                              "_blank"
                            )
                          }
                          style={{ width: "100%" }}
                        >
                          naviga
                          <NavigaIcon />
                        </Button>
                      </div>
                      {detailData?.reparti?.length > 0 && (
                        <div className="store-reparti">
                          <h6 className="storebar-title">Reparti</h6>
                          <div className="store-repart-box">
                            {detailData?.reparti?.map((reparti) => (
                              <div
                                className="store-repart-box__inner"
                                key={reparti?.nome}
                              >
                                <img
                                  src={reparti?.file}
                                  title={reparti?.nome}
                                  alt=""
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {detailData?.servizi?.length > 0 && (
                        <div className="store-servizi">
                          <h6 className="storebar-title">
                            Servizi disponibili
                          </h6>
                          <div className="store-servizi-box">
                            {detailData?.servizi?.map((servizi) => (
                              <div
                                className="store-servizi-box__inner"
                                key={servizi?.nome}
                              >
                                <img src={servizi?.file} alt="" />
                                <span>{servizi?.nome}</span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                      {detailData?.volantini?.length > 0 && (
                        <div className="store-volantini">
                          <h6 className="storebar-title">
                            Offerte disponibili
                          </h6>
                          <div className="store-volantini-box">
                            {detailData?.volantini
                              ?.slice(0, 2)
                              .map((volantini, index) => (
                                <div
                                  className="store-volantini-box__inner"
                                  key={index}
                                >
                                  <img src={volantini?.image} alt="" />
                                  <div className="store-volantini-content">
                                    <h3>{volantini?.name}</h3>
                                    <p>
                                      {volantini?.selloutStart}{" "}
                                      {volantini?.selloutEnd}
                                    </p>
                                    <Link
                                      className="store-volantini-btn"
                                      to={volantini?.url}
                                    >
                                      sfoglia <ArrowIcon />
                                    </Link>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      )}
                      {detailData?.aperture?.length > 0 && (
                        <div className="store-aperture">
                          <h6 className="storebar-title">Orari di apertura</h6>
                          <ul>
                            {detailData?.aperture?.map((day, index) => (
                              <li
                                key={index}
                                style={
                                  day.giorno_id === currentDay.toString()
                                    ? { fontWeight: "bold" }
                                    : {}
                                }
                              >
                                <span> {day?.giorno_nome} </span>
                                <span> {day?.orario} </span>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </Collapse>
                </div>
              ))}
            {loading && <span className="loadingText">Loading....</span>}
          </div>
        </div>
      </BottomSheet>
      <div className="map-container">
        <MapContainer
          center={mapCenter}
          zoom={13}
          scrollWheelZoom={true}
          touchZoom={true}
          style={{ height: "100%", width: "100%" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {pinMapData?.map((store) => (
            <Marker
              key={store?.id}
              position={store?.position}
              ref={(ref) => (markerRefs.current[store?.id] = ref)}
            >
              <Popup>
                <div className="popup-div">
                  <div className="store-details">
                    <div>
                      <span className="store-name">{store?.name}</span>
                      <span className="store-address">{store?.address}</span>
                      <span className="store-code">
                        {store?.cap} - {store?.city}
                      </span>
                    </div>
                    <img
                      src={serverResponse.config.clientIcon}
                      alt="Store Thumbnail"
                      className="store-thumbnail"
                    />
                  </div>
                  <div className="buttons-group flex-row">
                    <div className="volantino-buttons">
                      {/* <Button
                        color="white"
                        style={{ border: "1px solid", width: "144px" }}
                      >
                        volantino
                      </Button> */}
                      {width < BREAKPOINT.xs ? (
                        <Button
                          color="white"
                          style={{ border: "1px solid", width: "144px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStoreClickMobile(store?.id);
                            toggleDrawer();
                          }}
                        >
                          scheda
                        </Button>
                      ) : (
                        <Button
                          color="white"
                          style={{ border: "1px solid", width: "144px" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleStoreClick(store?.id);
                          }}
                        >
                          scheda
                        </Button>
                      )}
                    </div>
                    <Button
                      onClick={() =>
                        window.open(
                          `https://www.google.com/maps/dir/?api=1&destination=${store?.position[0]},${store?.position[1]}`,
                          "_blank"
                        )
                      }
                      style={{ width: "100%" }}
                    >
                      naviga
                      <NavigaIcon />
                    </Button>
                  </div>
                </div>
              </Popup>
            </Marker>
          ))}
          <MapUpdater />
        </MapContainer>
      </div>
    </div>
  );
};

export default StoreMap;
