import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { language } from "../../../languageProvider/language/language";
import { defaultLanguage } from "../../../helper/utility";

const AttachmentBodyMobile = ({ data, title, icon, type }) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-4 interactive-title">
        <span>{icon}</span>
        <h6 className="mb-0 ms-2">{title ? title : type}</h6>
      </div>
      <Link to={data.link} target="_blank">
        {language[defaultLanguage].allegato}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(AttachmentBodyMobile);
