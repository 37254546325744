import * as React from "react";
const StoreIcon = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.9995 0.675293C6.6087 0.675293 2.21484 5.1003 2.21484 10.6128C2.21484 17.3253 11.1872 22.9878 11.5564 23.2503C11.7041 23.3253 11.8518 23.3628 11.9995 23.3628C12.1472 23.3628 12.2949 23.3253 12.4425 23.2503C12.8118 23.0253 21.7841 17.2503 21.7841 10.6128C21.7841 5.1003 17.3902 0.675293 11.9995 0.675293ZM11.9995 21.4878C10.2641 20.2878 3.87637 15.6003 3.87637 10.5753C3.87637 6.0378 7.53176 2.32529 11.9995 2.32529C16.4671 2.32529 20.1225 6.0378 20.1225 10.5753C20.1225 15.5253 13.7348 20.2878 11.9995 21.4878Z"
      fill="currentcolor"
    />
    <path
      d="M14.5105 12.7504C15.0275 12.0379 15.2859 11.2129 15.2859 10.3504C15.2859 9.26294 14.8428 8.21294 14.0675 7.42544C12.4797 5.81294 9.85824 5.81294 8.23362 7.42544C7.45824 8.21294 7.01514 9.22544 7.01514 10.3504C7.01514 11.4754 7.45824 12.4879 8.23362 13.2754C9.04591 14.0629 10.0797 14.4754 11.1505 14.4754C11.889 14.4754 12.6275 14.2879 13.2551 13.8754L15.5444 16.1629C15.6921 16.3129 15.9136 16.3879 16.1351 16.3879C16.3567 16.3879 16.5782 16.3129 16.7259 16.1254C17.0582 15.7879 17.0582 15.2629 16.7259 14.9254L14.5105 12.7504ZM9.41514 12.0754C8.97207 11.6254 8.71362 10.9879 8.71362 10.3504C8.71362 9.71294 8.97207 9.07544 9.41514 8.62544C9.89514 8.13794 10.5228 7.91294 11.1505 7.91294C11.7782 7.91294 12.4059 8.13794 12.8859 8.62544C13.329 9.07544 13.5875 9.71294 13.5875 10.3504C13.5875 10.9879 13.329 11.6254 12.8859 12.0754C11.9259 13.0129 10.3751 13.0129 9.41514 12.0754Z"
      fill="currentcolor"
    />
  </svg>
);
export default StoreIcon;
