import * as React from "react";
const StoreCloseIcon = (props) => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle id="Ellipse 4" cx="6" cy="6.5" r="6" fill="red" />
  </svg>
);
export default StoreCloseIcon;
