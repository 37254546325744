import React from "react";

const LoginIcona = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Livello_2"
      data-name="Livello 2"
      viewBox="0 0 85.04 85.04"
      {...props}
    >
      <defs>
        <style>{".cls-2{fill:#6f6f6e}"}</style>
      </defs>
      <g id="Livello_2-2" data-name="Livello 2">
        <path
          d="M0 0h85.04v85.04H0z"
          style={{
            fill: "none"
          }}
        />
        <path
          d="M41.39 73.78c-7.05 0-13.74-2.25-19.34-6.51-.92-.7-1.09-2.01-.4-2.92s2-1.1 2.92-.4c4.87 3.7 10.68 5.65 16.81 5.65 15.33 0 27.81-12.47 27.81-27.81S56.71 13.98 41.38 13.98c-6.13 0-11.94 1.96-16.81 5.65-.92.7-2.23.52-2.92-.4s-.52-2.23.4-2.92c5.6-4.26 12.29-6.51 19.34-6.51 17.64 0 31.99 14.35 31.99 31.98S59.03 73.76 41.39 73.76Z"
          className="cls-1 cls-custom"
        />
        <path
          d="M42.78 43.88H13.75c-1.15 0-2.09-.93-2.09-2.09s.93-2.09 2.09-2.09h29.03c1.15 0 2.09.93 2.09 2.09s-.93 2.09-2.09 2.09Z"
          className="cls-1 cls-custom"
        />
        <path
          d="m28.95 58.86-2.85-3.05L41.18 41.8 26.1 27.78l2.85-3.05L47.3 41.8 28.95 58.86z"
          className="cls-1 cls-custom"
        />
      </g>
    </svg>
  );
};

export default LoginIcona;
