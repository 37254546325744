import axios from "axios";

const url = `${process.env.REACT_APP_ENDPOINT}/analitic/`;

const API = axios.create({
  baseURL: url,
  validateStatus: function(status) {
    return status < 300;
  }
});

export const visit = (data) => API.post("get-data", data);

export const sendEvent = (data) => API.post("event", data);

export const sendEventVerticalPage = (data) =>
  API.post("open-vertical-pages", data);
