import React, { useState } from "react";
import { Modal } from "reactstrap";
import { connect } from "react-redux";
import { closeInteractivityModal } from "../../redux/modal/action";
import { iconsObj, sendAnalyticsEvent } from "../../helper/utility";
import ModalBody from "reactstrap/lib/ModalBody";
import Row from "reactstrap/lib/Row";
import { isIOS } from "react-device-detect";
import Close from "../../assets/images/icons/close.svg";
import ContentModal from "./modalbody/ContentModal";
import Marker from "../leaflet/markers/Marker";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import ManagerFactory from "../../managers/ManagerFactory";
import LoaderModal from "../common/LoaderModal";
import ModalFooter from "reactstrap/lib/ModalFooter";
import useCurrentWidth from "../../hooks/widthHook";
import { BREAKPOINT } from "../../helper/constant";
import { styles } from "./header.styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebookSquare,
  // faInstagramSquare,
  // faTwitterSquare,
  faWhatsappSquare,
} from "@fortawesome/free-brands-svg-icons";
import ContentModalMobile from "./modalbody/ContentModalMobile";

const manager = ManagerFactory.getManager();

const ModalComponent = (props) => {
  const otherPropsModal = props.modalRedux.otherProps;
  const fakeServerConfig = props.fakeServer.config;
  const productUrl = serverResponse.config.shareFlyerURL;

  const [actTab, setActTab] = useState("plus");
  const [itemId, setItemId] = useState();
  const width = useCurrentWidth();

  const [scrolling, setScrolling] = useState(false);

  const scrollToSlide = (id) => {
    const element = document.getElementById(id);
    if (element && !scrolling) {
      setScrolling(true);
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });

      // Use setTimeout based on scroll duration to manage when scrolling is complete
      setTimeout(() => {
        setScrolling(false); // Reset scrolling flag
      }, 800); // Adjust the duration as needed to match your scroll animation time
    }
  };

  const { contentModal: info } = props.modalRedux;

  const shareOnFacebook = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_facebook,
      String(otherPropsModal.info.item_id)
    );

    let fb_share_url = "https://www.facebook.com/share.php?u=";
    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    window.open(
      fb_share_url + encodeURIComponent(page_url),
      "_blank",
      "location=yes,height=570,width=520,scrollbars=yes,status=yes"
    );
  };

  const shareOnWhatsapp = () => {
    sendAnalyticsEvent(
      gaEventLabel.share_product_whatsapp,
      String(otherPropsModal.info.item_id)
    );

    let page_url =
      productUrl + "/share/product.php?c=" + otherPropsModal.info.item_id;
    if (window.ReactNativeWebView !== undefined && isIOS) {
      let link = encodeURIComponent(page_url);
      let title = serverResponse.config.shareProductSentence;
      var str = title + " " + link;
      var obj = { action: "whatsapp", value: str };
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
    } else {
      window.open(
        "http://api.whatsapp.com/send?text=" +
          serverResponse.config.shareProductSentence +
          " " +
          encodeURIComponent(page_url),
        "_blank",
        "location=yes,height=570,width=520,scrollbars=yes,status=yes"
      );
    }
  };

  const title_type = manager.getTitleType();

  const activedInteractivities = !props.modalRedux.loading
    ? otherPropsModal.info.markers.map((el) => el.type)
    : [];

  // const space = width <= BREAKPOINT.xs ? "102px" : "92px";

  return (
    <Modal
      className={`main-modal custom-bs-modal ${props.className} ${
        info.related &&
        info.related.length > 0 &&
        otherPropsModal.type === "plus"
          ? "has-related-products"
          : ""
      }`}
      // style={{ height: isMobile ? "95vh" : "inherit" }}
      centered
      isOpen={props.modalRedux.isModalOpen}
      toggle={() => {
        props.closeInteractivityModal();
      }}
      size="xl"
      onOpened={() => {
        // props.isModalOpen(true);
        if (otherPropsModal.disableSwipe !== undefined)
          otherPropsModal.disableSwipe();
      }}
      onClosed={() => {
        // this.props.isModalOpen(false);
        if (otherPropsModal.enableSwipe !== undefined)
          otherPropsModal.enableSwipe();
      }}
    >
      <div className="row h-sm-100">
        <div
          className={`col-12 modal-header-cs ${
            fakeServerConfig.hide_share_product && "text-right"
          }`}
        >
          <div className="modal-header-wrap asas">
            {!fakeServerConfig.hide_share_product && (
              <div style={styles.style_container_header}>
                <div style={styles.socialIcons}>
                  {/* <div className="social-icon">
                      <FontAwesomeIcon icon={faInstagramSquare} />
                    </div> */}
                  <div
                    className="social-icon"
                    onClick={() => shareOnFacebook()}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faFacebookSquare} />
                  </div>
                  {/* <div className="social-icon">
                      <FontAwesomeIcon icon={faTwitterSquare} />
                    </div> */}
                  <div
                    className="social-icon"
                    onClick={() => shareOnWhatsapp()}
                    style={{ cursor: "pointer" }}
                  >
                    <FontAwesomeIcon icon={faWhatsappSquare} />
                  </div>
                </div>
                {manager.closeButtonIcon() ? (
                  <FontAwesomeIcon
                    onClick={() => props.closeInteractivityModal()}
                    icon={faTimes}
                    color="#0D0040"
                    size="lg"
                    className="cursor-pointer"
                  />
                ) : (
                  <span
                    onClick={() => props.closeInteractivityModal()}
                    style={{ cursor: "pointer" }}
                  >
                    Torna al catalogo
                  </span>
                )}
              </div>
            )}
            {fakeServerConfig.hide_share_product && (
              <FontAwesomeIcon
                onClick={() => props.closeInteractivityModal()}
                icon={faTimes}
                color="#0D0040"
                size="lg"
                className="cursor-pointer ml-auto d-block"
              />
            )}
            {/* <hr className="my-0" style={styles.hr_style} /> */}
          </div>
        </div>
        {width <= BREAKPOINT.xs && (
          <div className="col-12 modal-footer-cs productdetails-footer">
            {Object.keys(title_type).length > 1 &&
              props.modalRedux.isProduct && (
                <ModalFooter
                  style={{
                    justifyContent:
                      width <= BREAKPOINT.xs ? "space-between" : "center",
                    flexWrap: "nowrap",
                  }}
                >
                  {Object.keys(title_type)
                    .filter((el) => activedInteractivities.includes(el))
                    .map((el, index) => {
                      return (
                        <React.Fragment key={`${el}_${index}`}>
                          <button
                            // disabled={!activedInteractivities.includes(el)}
                            onClick={() => {
                              setActTab(el);
                              scrollToSlide(el);
                            }}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",

                              // width: `calc(100% / ${
                              //   Object.keys(title_type).length
                              // })`,
                              // flexDirection:
                              //   width <= BREAKPOINT.xs ? "column-reverse" : "row",
                              // fontSize: "10px",
                              borderRadius: actTab === el ? "4px" : "0px",
                              background:
                                actTab === el ? `var(--primary-color)` : "",
                              // textAlign: "center",
                              color:
                                actTab === el ||
                                activedInteractivities.includes(el)
                                  ? `black`
                                  : "lightgrey",
                              // fontWeight:
                              //   actTab === el || activedInteractivities.includes(el)
                              //     ? "normal"
                              //     : "",

                              filter: activedInteractivities.includes(el)
                                ? "none"
                                : "opacity(0.3)",
                              zIndex: actTab === el ? "1" : "unset",
                            }}
                            className={` h-100 m-0 btn custom_inter overlay p-0 ${
                              actTab === el ? "shadow active" : ""
                            }`}
                          >
                            <p
                              style={{
                                color: actTab === el ? "white" : "#0D0040",
                                marginRight:
                                  width <= BREAKPOINT.xs ? "0px" : "5px",
                                // fontSize: "1.3em",
                                fontSize: "16px",
                              }}
                            >
                              {title_type[el]}{" "}
                            </p>

                            {/* {!activedInteractivities.includes(el) ? (
                            <div
                              className={`${
                                actTab === el ? "vertical" : ""
                              } `}
                              style={{ color: "#b0adc3" }}
                            >
                              {iconsObj[el].component}
                            </div>
                          ) :  */}
                            {actTab === el ? (
                              <div
                                className={`${
                                  actTab === el ? "vertical" : ""
                                } `}
                                style={{ color: "white" }}
                              >
                                {iconsObj[el].component}
                              </div>
                            ) : (
                              activedInteractivities.includes(el) &&
                              otherPropsModal &&
                              otherPropsModal !== {} &&
                              !props.modalRedux.loading && (
                                <Marker
                                  from_leaflet={false}
                                  dynamic_price={
                                    props.modalRedux.otherProps.dynamic_price
                                  }
                                  dynamic_info={
                                    props.modalRedux.otherProps.dynamic_info
                                  }
                                  show_dynamic_price={
                                    props.modalRedux.otherProps
                                      .show_dynamic_price
                                  }
                                  zoom={otherPropsModal.zoom}
                                  disableSwipe={otherPropsModal.disableSwipe}
                                  enableSwipe={otherPropsModal.enableSwipe}
                                  key={index}
                                  info={otherPropsModal.info}
                                  markerInfo={
                                    otherPropsModal.info.markers.filter(
                                      (info) => info.type === el
                                    )[0]
                                  }
                                  leafletSwipe={otherPropsModal.leafletSwipe}
                                  navMarkers={otherPropsModal.navMarkers}
                                  setSwipe={otherPropsModal.setSwipe}
                                  isSwipeDisabed={
                                    otherPropsModal.isSwipeDisabed
                                  }
                                  type={el}
                                  custom_class={"custom_width_marker"}
                                />
                              )
                            )}
                          </button>
                        </React.Fragment>
                      );
                    })}
                </ModalFooter>
              )}
          </div>
        )}
        <div className="col-12 modal-body-cs my-5">
          <ModalBody
            className="d-md-flex align-items-center overflowhidden"
            // style={{ height: "calc(100% - 50px)" }}
            // style={{
            //   height: `calc(${
            //     width >= BREAKPOINT.xs ? "80vh" : "90vh"
            //   } - ${space})`,
            // }}
          >
            {props.modalRedux.loading ? (
              <Row>
                <LoaderModal width="50vw" height="50vh" />
              </Row>
            ) : null}
            {/* CONTENUTO DINAMICO MODALE */}

            {!props.modalRedux.loading && (
              <>
                <div
                  className={`xs-h-auto modal-innerBlock modal-content-main-wrap ${
                    width >= BREAKPOINT.xs ? "w-100" : ""
                  } ${
                    otherPropsModal.type === "play" ? "w-100 m-0" : ""
                  }  ${manager.getRowClass(props.modalRedux.otherProps.type)}`}
                >
                  {width <= BREAKPOINT.xs ? (
                    <>
                      <ContentModalMobile
                        activeTab={actTab}
                        setActiveTab={setActTab}
                        scrolling={scrolling}
                      />
                    </>
                  ) : (
                    <ContentModal {...props.modalRedux.otherProps} />
                  )}
                </div>
              </>
            )}
          </ModalBody>
        </div>
        <div className="col-12 modal-footer-cs d-none d-sm-block">
          <div className="modal-footer-wrap-cs">
            {Object.keys(title_type).length > 1 &&
              props.modalRedux.isProduct && (
                <ModalFooter
                  style={{
                    justifyContent:
                      width <= BREAKPOINT.xs ? "space-between" : "center",
                    flexWrap: "nowrap",
                  }}
                >
                  {Object.keys(title_type)
                    // .reverse()
                    .map((el, index) => {
                      return (
                        <React.Fragment key={`${el}_${index}`}>
                          <button
                            onClick={() => {
                              if (
                                document.getElementById(
                                  `${el}-${otherPropsModal.info.item_id}`
                                )
                              )
                                document
                                  .getElementById(
                                    `${el}-${otherPropsModal.info.item_id}`
                                  )
                                  .click();
                              setItemId(otherPropsModal.info.item_id);
                            }}
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",

                              width: `calc(100% / ${
                                Object.keys(title_type).length
                              })`,
                              flexDirection:
                                width <= BREAKPOINT.xs
                                  ? "column-reverse"
                                  : "row",
                              fontSize: "10px",
                              borderRadius:
                                otherPropsModal.type === el ? "8px" : "0px",
                              background:
                                otherPropsModal.type === el
                                  ? `var(--primary-color)`
                                  : "",
                              textAlign: "center",
                              color:
                                otherPropsModal.type === el ||
                                activedInteractivities.includes(el)
                                  ? `black`
                                  : "lightgrey",
                              fontWeight:
                                otherPropsModal.type === el ||
                                activedInteractivities.includes(el)
                                  ? "normal"
                                  : "",

                              filter: activedInteractivities.includes(el)
                                ? "none"
                                : "opacity(0.3)",
                              zIndex:
                                otherPropsModal.type === el ? "1" : "unset",
                            }}
                            className={` h-100 m-0 btn custom_inter overlay p-0 ${
                              otherPropsModal.type === el ? "shadow" : ""
                            }`}
                          >
                            <p
                              style={{
                                color:
                                  otherPropsModal.type === el
                                    ? "white"
                                    : "#0D0040",
                                marginRight:
                                  width <= BREAKPOINT.xs ? "0px" : "5px",
                                // fontSize: "1.3em",
                                fontSize: "16px",
                              }}
                            >
                              {title_type[el]}{" "}
                            </p>

                            {!activedInteractivities.includes(el) ? (
                              <div
                                className={`${
                                  otherPropsModal.type === el ? "vertical" : ""
                                } `}
                                style={{
                                  // width: "40px",
                                  color: "#b0adc3",
                                }}
                              >
                                {iconsObj[el].component}
                              </div>
                            ) : otherPropsModal.type === el ? (
                              <div
                                className={`${
                                  otherPropsModal.type === el ? "vertical" : ""
                                } `}
                                style={{ width: "40px", color: "white" }}
                              >
                                {iconsObj[el].component}
                              </div>
                            ) : (
                              activedInteractivities.includes(el) &&
                              otherPropsModal &&
                              otherPropsModal !== {} &&
                              !props.modalRedux.loading && (
                                <Marker
                                  from_leaflet={false}
                                  dynamic_price={
                                    props.modalRedux.otherProps.dynamic_price
                                  }
                                  dynamic_info={
                                    props.modalRedux.otherProps.dynamic_info
                                  }
                                  show_dynamic_price={
                                    props.modalRedux.otherProps
                                      .show_dynamic_price
                                  }
                                  zoom={otherPropsModal.zoom}
                                  disableSwipe={otherPropsModal.disableSwipe}
                                  enableSwipe={otherPropsModal.enableSwipe}
                                  key={index}
                                  info={otherPropsModal.info}
                                  markerInfo={
                                    otherPropsModal.info.markers.filter(
                                      (info) => info.type === el
                                    )[0]
                                  }
                                  leafletSwipe={otherPropsModal.leafletSwipe}
                                  navMarkers={otherPropsModal.navMarkers}
                                  setSwipe={otherPropsModal.setSwipe}
                                  isSwipeDisabed={
                                    otherPropsModal.isSwipeDisabed
                                  }
                                  type={el}
                                  custom_class={"custom_width_marker"}
                                />
                              )
                            )}
                          </button>
                        </React.Fragment>
                      );
                    })}
                </ModalFooter>
              )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
    fakeServer: state.fakeServer,
  };
};

export default connect(mapStateToProps, {
  closeInteractivityModal,
})(ModalComponent);
