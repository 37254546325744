import * as React from "react";
const InpublishLogo = (props) => (
  <svg
    id="Livello_2"
    data-name="Livello 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 209.59 150.39"
    {...props}
  >
    <g id="Livello_1-2" data-name="Livello 1">
      <g>
        <g>
          <path
            d="m1.23,107.08c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-2.9c0-.36.12-.66.35-.91.23-.25.53-.38.88-.38h3.38c.36,0,.66.13.91.38.25.25.38.55.38.91v2.9c0,.36-.13.65-.38.88-.25.23-.55.35-.91.35H1.23Zm.43,33.11c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-25.44c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.58c.36,0,.65.12.88.35.23.23.35.53.35.88v25.43c0,.36-.12.65-.35.88-.23.23-.53.35-.88.35H1.66Z"
            fill="#171636"
          />
          <path
            d="m13.29,140.18c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-25.44c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.52c.36,0,.65.12.88.35.23.23.35.53.35.88v2.36c.93-1.22,2.1-2.21,3.51-2.98,1.41-.77,3.23-1.15,5.45-1.15s4.3.51,5.93,1.53c1.63,1.02,2.86,2.43,3.7,4.24.84,1.8,1.26,3.91,1.26,6.3v15.13c0,.36-.12.65-.35.88-.23.23-.53.35-.88.35h-2.68c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-14.86c0-2.5-.61-4.46-1.83-5.88-1.22-1.41-3-2.12-5.37-2.12-2.22,0-4,.71-5.34,2.12-1.34,1.41-2.01,3.37-2.01,5.88v14.86c0,.36-.12.65-.35.88-.23.23-.53.35-.88.35h-2.68,0Z"
            fill="#171636"
          />
          <path
            d="m44.53,150.38c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-35.63c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.47c.36,0,.65.12.88.35.23.23.35.53.35.88v2.36c.86-1.18,2-2.16,3.43-2.95,1.43-.79,3.22-1.18,5.37-1.18,2,0,3.71.34,5.13,1.02,1.41.68,2.58,1.61,3.49,2.79.91,1.18,1.6,2.52,2.07,4.03.47,1.5.73,3.11.8,4.83.03.57.05,1.18.05,1.83s-.02,1.25-.05,1.83c-.07,1.68-.34,3.28-.8,4.8-.47,1.52-1.15,2.86-2.07,4.03-.91,1.16-2.08,2.09-3.49,2.79-1.41.7-3.12,1.05-5.13,1.05s-3.82-.38-5.23-1.15c-1.41-.77-2.57-1.73-3.46-2.87v12.45c0,.36-.11.65-.32.88-.22.23-.5.35-.86.35h-2.63Zm11.37-14.01c1.86,0,3.32-.4,4.37-1.21,1.05-.8,1.82-1.86,2.28-3.17.47-1.3.73-2.71.8-4.21.03-1.04.03-2.08,0-3.11-.07-1.5-.34-2.9-.8-4.21-.47-1.3-1.23-2.36-2.28-3.17-1.05-.8-2.51-1.21-4.37-1.21-1.75,0-3.18.41-4.27,1.23-1.09.83-1.9,1.87-2.41,3.14-.52,1.27-.81,2.57-.88,3.89-.04.57-.05,1.25-.05,2.04s.02,1.48.05,2.09c.03,1.25.33,2.49.88,3.7.55,1.22,1.39,2.22,2.5,3,1.11.79,2.5,1.18,4.18,1.18h0Z"
            fill="#171636"
          />
          <path
            d="m84.35,140.72c-2.29,0-4.23-.51-5.82-1.53-1.59-1.02-2.8-2.44-3.62-4.27-.83-1.83-1.23-3.92-1.23-6.28v-15.13c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.68c.36,0,.65.12.88.35.23.23.35.53.35.88v14.86c0,5.33,2.33,7.99,6.98,7.99,2.22,0,4-.7,5.34-2.12,1.34-1.41,2.01-3.37,2.01-5.88v-14.86c0-.36.12-.65.35-.88.23-.23.52-.35.88-.35h2.68c.36,0,.64.12.86.35.21.23.32.53.32.88v25.43c0,.36-.11.65-.32.88-.22.23-.5.35-.86.35h-2.52c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-2.36c-.97,1.25-2.14,2.25-3.51,3-1.38.75-3.19,1.13-5.45,1.13h0Z"
            fill="#171636"
          />
          <path
            d="m118.59,140.72c-2.15,0-3.93-.38-5.37-1.15-1.43-.77-2.58-1.73-3.43-2.87v2.25c0,.36-.12.65-.35.88-.23.23-.53.35-.88.35h-2.47c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-35.63c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.63c.36,0,.64.12.86.35.21.23.32.53.32.88v12.34c.89-1.11,2.05-2.04,3.46-2.79,1.41-.75,3.16-1.13,5.23-1.13s3.71.35,5.12,1.05c1.41.7,2.58,1.65,3.49,2.87.91,1.22,1.6,2.59,2.07,4.13.47,1.54.73,3.15.8,4.83.03.58.05,1.11.05,1.61s-.02,1.04-.05,1.61c-.07,1.72-.34,3.35-.8,4.88-.47,1.54-1.15,2.91-2.07,4.1-.91,1.2-2.08,2.15-3.49,2.84-1.41.7-3.12,1.05-5.12,1.05h0Zm-1.13-4.35c1.86,0,3.32-.4,4.37-1.21,1.05-.8,1.82-1.86,2.28-3.17.47-1.3.73-2.71.8-4.21.03-1.04.03-2.08,0-3.11-.07-1.5-.34-2.9-.8-4.21-.47-1.3-1.22-2.36-2.28-3.17-1.05-.8-2.51-1.21-4.37-1.21-1.68,0-3.08.4-4.18,1.18-1.11.79-1.94,1.79-2.5,3-.55,1.22-.85,2.45-.88,3.7-.04.58-.05,1.26-.05,2.07s.02,1.5.05,2.07c.07,1.33.37,2.62.88,3.89.52,1.27,1.32,2.32,2.41,3.14,1.09.83,2.51,1.23,4.27,1.23h0Z"
            fill="#171636"
          />
          <path
            d="m136.76,140.18c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-35.63c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.52c.39,0,.7.12.91.35.21.23.32.53.32.88v35.63c0,.36-.11.65-.32.88-.22.23-.52.35-.91.35h-2.52Z"
            fill="#171636"
          />
          <path
            d="m147.93,107.08c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-2.9c0-.36.12-.66.35-.91.23-.25.53-.38.88-.38h3.38c.36,0,.66.13.91.38.25.25.38.55.38.91v2.9c0,.36-.13.65-.38.88-.25.23-.55.35-.91.35h-3.38Zm.43,33.11c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-25.44c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.58c.36,0,.65.12.88.35.23.23.35.53.35.88v25.43c0,.36-.12.65-.35.88-.23.23-.53.35-.88.35h-2.58Z"
            fill="#171636"
          />
          <path
            d="m168.32,140.72c-2,0-3.72-.25-5.15-.75-1.43-.5-2.59-1.11-3.49-1.83-.9-.72-1.57-1.43-2.01-2.15-.45-.72-.69-1.29-.72-1.72-.04-.39.09-.7.37-.91.29-.22.57-.32.86-.32h2.41c.18,0,.33.03.45.08.12.05.3.21.51.45.46.5.98,1,1.55,1.5.57.5,1.28.91,2.12,1.23.84.32,1.89.48,3.14.48,1.83,0,3.33-.35,4.51-1.05,1.18-.7,1.77-1.73,1.77-3.08,0-.89-.24-1.61-.72-2.15-.48-.54-1.34-1.02-2.58-1.45-1.23-.43-2.93-.88-5.07-1.34-2.15-.5-3.85-1.12-5.1-1.85-1.25-.73-2.15-1.61-2.68-2.63s-.8-2.17-.8-3.46.39-2.6,1.18-3.84c.79-1.23,1.94-2.25,3.46-3.03,1.52-.79,3.42-1.18,5.71-1.18,1.86,0,3.45.23,4.78.7,1.32.47,2.41,1.05,3.27,1.74.86.7,1.5,1.39,1.93,2.07.43.68.66,1.25.7,1.72.03.36-.07.65-.32.88-.25.23-.54.35-.86.35h-2.25c-.25,0-.45-.05-.62-.16-.16-.11-.31-.23-.45-.38-.36-.47-.78-.93-1.26-1.4-.48-.47-1.11-.85-1.88-1.15-.77-.3-1.78-.46-3.03-.46-1.79,0-3.13.38-4.02,1.13-.9.75-1.34,1.7-1.34,2.84,0,.68.2,1.29.59,1.83.39.54,1.15,1.02,2.25,1.45,1.11.43,2.76.9,4.93,1.4,2.36.47,4.22,1.09,5.58,1.88,1.36.79,2.33,1.7,2.9,2.74s.86,2.24.86,3.6c0,1.5-.43,2.88-1.29,4.13-.86,1.25-2.15,2.25-3.86,2.98-1.72.73-3.83,1.1-6.33,1.1h.02Z"
          />
          <path
            d="m185.92,140.18c-.36,0-.65-.12-.88-.35-.23-.23-.35-.53-.35-.88v-35.63c0-.36.12-.65.35-.88.23-.23.53-.35.88-.35h2.68c.39,0,.7.12.91.35.22.23.32.53.32.88v12.5c.97-1.22,2.15-2.2,3.54-2.95,1.39-.75,3.17-1.13,5.31-1.13,2.32,0,4.3.51,5.93,1.53,1.63,1.02,2.86,2.43,3.7,4.24.84,1.8,1.26,3.91,1.26,6.3v15.13c0,.36-.11.65-.32.88-.21.23-.52.35-.91.35h-2.68c-.36,0-.66-.12-.88-.35-.23-.23-.35-.53-.35-.88v-14.86c0-2.5-.61-4.46-1.82-5.88-1.22-1.41-3-2.12-5.37-2.12-2.22,0-4,.71-5.37,2.12-1.36,1.41-2.04,3.37-2.04,5.88v14.86c0,.36-.11.65-.32.88-.21.23-.52.35-.91.35h-2.68Z"
            fill="#171636"
          />
        </g>
        <path
          d="m165.56,5.32c-1.09-1.91-2.56-3.34-4.39-4.25-2.13-1.07-4.26-1.07-7.46-1.07h-5c-38.51,0-50.55,16.58-55.78,22.76-2.23-3.09-5.2-5.62-8.69-7.35-5.31-2.66-11.33-2.66-23.33-2.66h-5c-3.21,0-5.33,0-7.45,1.05-1.97.92-3.47,2.37-4.44,4.3-1.07,2.13-1.07,4.26-1.07,7.46v41.24c0,3.21,0,5.33,1.09,7.51,1.09,1.91,2.56,3.34,4.39,4.25,2.13,1.07,4.26,1.07,7.46,1.07h14.77c2.4,0,3.61,0,4.51.11.8.12,1.55.34,2.22.67.53.23,1.16.64,2.19,1.33.45.3.97.64,1.58,1.04l8.86,5.82c.12.1.24.16.32.21l.06.06.13.06c.21.1.4.15.54.19.07.02.13.03.16.04.57.35,1.21.35,1.64.35s.96,0,1.55-.14l.09-.03.94-.47.06-.06c.08-.03.19-.1.32-.21l8.86-5.83c1.8-1.24,2.95-2.02,3.8-2.38.62-.31,1.37-.53,2.16-.65.92-.12,2.13-.12,4.53-.12h38.49c3.21,0,5.33,0,7.45-1.05,1.97-.92,3.47-2.37,4.44-4.3,1.07-2.13,1.07-4.26,1.07-7.46V12.84c0-3.21,0-5.33-1.09-7.51h0Zm-67.66,70.01v-26.08c0-10.03,0-15.55,1.48-18.75,1.59-2.93,11.72-20.83,49.32-20.83h5c1.03,0,2.38,0,2.98.06.06.6.06,1.96.06,2.98v53.99c0,1.03,0,2.38-.06,2.99-.57.06-1.99.06-2.98.06h-38.49c-2.97,0-4.45,0-6.22.25l-.08.02c-1.48.37-3,.75-4.37,1.37-1.62.62-2.99,1.48-5.42,3.14l-1.21.81h0Zm-45.16-8.75V25.34c0-1.03,0-2.39.06-2.99.57-.06,1.99-.06,2.98-.06h5c10.42,0,15.66,0,18.88,1.61,2.88,1.49,5.09,3.71,6.58,6.57,1.62,3.23,1.62,8.43,1.62,18.77v26.08l-1.25-.84c-.79-.47-1.45-.89-2.04-1.27-1.22-.78-2.19-1.4-3.3-1.82-1.52-.69-3.03-1.16-4.5-1.41-1.77-.25-3.35-.25-6.22-.25h-14.76c-1.02,0-2.35,0-2.96-.06-.08-.51-.08-2-.08-3.1h0Z"
          fill="#8f61db"
        />
      </g>
    </g>
  </svg>
);
export default InpublishLogo;
