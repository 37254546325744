import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const WorldBody = (props) => {
  const markerInfo = props.modalRedux?.otherProps?.markerInfo;
  const images = props.modalRedux?.contentModal?.images;

  const uncroppedImage = images?.find((image) => !image.cropped);
  const croppedImage = images?.find((image) => image.cropped);
  const selectedImage = uncroppedImage || croppedImage;

  //Modifica effettuata per retrocompatibilità della funzionalità
  let interactivityImage = [];
  let interactivityLink = null;

  if (typeof(markerInfo.data) == "string" ) {
    interactivityLink = markerInfo.data;
  } else {
    interactivityImage = markerInfo.data.img;
    interactivityLink = markerInfo.data.link;
  }

  return (
    <div className="link-type-interactivity text-center">
      {markerInfo?.title &&
        <h6>
          {markerInfo?.title}
        </h6>
      }
      <img src={interactivityImage.length > 0 ? interactivityImage : selectedImage?.image_file} />
      <Link to={interactivityLink}>vai</Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(WorldBody);
