import React from "react";
import DefaultManager from "./Default.manager";
import CloseCart from "../components/share/CloseCart";

class CiesseItaliaManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }
  buttonCloseGrocery() {
    return false;
  }

  leftButtonGroceryComponent(toggle) {
    return <CloseCart toggle={toggle} />;
  }

  actionCompleteOrder(fc) {
    const url = fc();

    const numero = "390909576792";

    window.open(`https://wa.me/${numero}?text=${url}`, "_blank");
  }

  actionOnlyWA() {
    return true;
  }

  closeModalAfterAddProd() {
    return true;
  }

  visibilityConditionShareGrocery() {
    return true;
  }

  labelCompleteOrder() {
    return "send_ordine";
  }
  closeButtonIcon() {
    return false;
  }
}

export default CiesseItaliaManager;
