import React, { Component } from "react";

// import { Scrollbars } from "react-custom-scrollbars";
import {
  Popover,
  PopoverHeader,
  PopoverBody,
  Input,
  // Tooltip,
  // Button
} from "reactstrap";

import { connect } from "react-redux";
import actions from "../../redux/groceryList/action";
import GroceryProduct from "./GroceryProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faPlus,
  // faMinus,
  faTimes,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
// import ClearChart from "../../assets/images/icons/clear_chart.png";
// import CartRemove from "../../assets/images/icons/Cart_delete.svg";
// import { strings } from "../../util/data/strings";

import { serverResponse } from "../../util/fakeServer";
import { isMobile } from "react-device-detect";
import RemoveAllBtn from "./RemoveAllBtn";
import { defaultLanguage, getCustomActions } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
// import { AddCarrello, AddLista, Negozio } from "../../assets/images/icone";
import ManagerFactory from "../../managers/ManagerFactory";
// import ForwardIcon from "../../assets/icons/forward.icon";
import SaveIcon from "../../assets/icons/save.icon";
import { CartIcon } from "../../assets/icons";
import CloseCartIcon from "../../assets/icons/closeCart.icon";
// import AnimatedBox from "../AnimatedBox/AnimatedBox.component";
const manager = ManagerFactory.getManager();
class GroceryList extends Component {
  constructor(props) {
    super(props);
    this.addNewProductRef = React.createRef();
  }
  state = {
    newProductTitle: "",
    newProductPrice: "",
    newProductQuantity: 1,
    saveTooltipOpen: false,
    deleteAllTooltipOpen: false,
  };

  handleChangeTitle = (e) => {
    this.setState({ newProductTitle: e.target.value });
  };

  handleChangePrice = (e) => {
    this.setState({ newProductPrice: e.target.value });
  };

  handleKeyUp = (event) => {
    var keycode = event.which;
    if (
      !(
        event.shiftKey === false &&
        (keycode === 46 ||
          keycode === 8 ||
          keycode === 37 ||
          keycode === 39 ||
          (keycode >= 48 && keycode <= 57))
      )
    ) {
      event.preventDefault();
    }
  };

  handleChangeQuantity = (type) => {
    let newQuantity;
    if (type === 1) {
      // increment
      newQuantity = this.state.newProductQuantity + 1;
    } else {
      //decrement
      if (this.state.newProductQuantity > 1) {
        newQuantity = this.state.newProductQuantity - 1;
      } else {
        newQuantity = this.state.newProductQuantity;
      }
    }
    this.setState({ newProductQuantity: newQuantity });
  };

  saveNewProduct = () => {
    let { newProductTitle, newProductPrice, newProductQuantity } = this.state;

    if (newProductTitle === "" || newProductPrice === 0) return;

    this.props.addProduct({
      item_id: Date.now(),
      description: "",
      field1: newProductTitle,
      filed2: "",
      filed3: "",
      filed4: "",
      grocery_label: "pz.",
      images: [
        {
          image_file: "",
          cropped: false,
        },
      ],
      price: Math.abs(newProductPrice),
      price_label: "€" + newProductPrice,
      quantity: newProductQuantity,
      equivalence: 1,
      quantity_step: 1,
      done: 0,
      variety: undefined,
      offer_price: null,
      prices: null,
      promo: null,
      sku: null,
      skul: "",
    });

    this.setState({
      newProductTitle: "",
      newProductPrice: "",
      newProductQuantity: 1,
    });
  };

  toggleSaveTooltip = () => {
    this.setState({ saveTooltipOpen: !this.state.saveTooltipOpen });
  };

  toggleDeleteAllTooltip = () => {
    this.setState({ deleteAllTooltipOpen: !this.state.deleteAllTooltipOpen });
  };

  showCustomProductOnMobile = () => {
    if (isMobile) {
      document.getElementById("prodottoNonPresente").style.display = "block";
    }
  };

  hideCustomProductOnMobile = () => {
    if (isMobile) {
      document.getElementById("prodottoNonPresente").style.display = "none";
    }
  };

  stopPropagation = (event) => {
    event.stopPropagation();
  };

  sendGroceryListToMarket = () => {
    var tmpProducts = this.props.groceryList.list;

    tmpProducts.forEach((element) => {
      element.image_file = element.images[0]
        ? element.images[0].image_file
        : "";
    });

    var idParam = Math.random().toString(36).substr(2, 16);
    var product = this.props.groceryList.list;
    for (var i = 0; i < product.length; i++) {
      product[i].description = product[i].description
        ? product[i].description.substring(0, 80)
        : product[i].field1.substring(0, 80);
      product[i].field1 = product[i].field1.substring(0, 80);
      product[i].field2 = product[i].field2
        ? product[i].field2.substring(0, 80)
        : "";
      product[i].field3 = product[i].field3
        ? product[i].field3.substring(0, 80)
        : "";
      product[i].field4 = product[i].field4
        ? product[i].field4.substring(0, 80)
        : "";
    }

    var exportObj = {
      id_param_url: idParam,
      id_campagna: this.props.groceryList.idCampaign,
      flyer_url:
        window.location.host === "preview.interattivo.net"
          ? "preview"
          : `${process.env.REACT_APP_VIEW}/${serverResponse.config.client_code}/${serverResponse.config.slug}`,
      // "prodotti": this.props.groceryList.list
      prodotti: product,
      pid: this.props.authJWT.user ? this.props.authJWT.user.id : null,
    };

    if (manager.actionOnlyWA()) {
      getCustomActions().exportList(exportObj);
      const linkShare = getCustomActions().getShareListLink(idParam);
      return linkShare;
    }

    getCustomActions().sendList(exportObj, idParam, true);
  };

  render() {
    const { newProductTitle, newProductPrice, newProductQuantity } = this.state;

    const mobile_style = {
      height: "calc(100vh - 125px)",
      width: "100%",
    };

    const desktop_style = {
      backgroundColor: "white",
    };

    var totPrice = 0;
    let list = this.props.groceryList.list.map((product, key) => {
      //totPrice += parseFloat(product.price) * parseInt(product.quantity);
      totPrice += parseFloat(product.currentPrice);

      return (
        <GroceryProduct
          no_price={product.no_price}
          promo={product.promo}
          prices={product.prices}
          key={key}
          id={product.item_id}
          title={product.field1}
          variety={product.variety}
          price={product.currentPrice}
          grocery_label={product.grocery_label}
          quantity={product.quantity}
          quantity_step={product.quantity_step}
          done={product.done}
          images={product.images}
        />
      );
    });

    let grocery_label = serverResponse.config.send_grocery_list_to_market
      ? language[defaultLanguage].il_tuo_carrello
      : language[defaultLanguage].la_tua_lista_della_spesa;

    return (
      <Popover
        className="header-popover-width custom-popover grocery-list-popover"
        isOpen={this.props.isOpen}
        target={this.props.target}
        placement={this.props.placement}
        toggle={this.props.toggle}
        trigger="block"
        boundariesElement="window"
        onClick={this.stopPropagation}
      >
        <PopoverHeader
          className="custom-popover-header text-center m-0"
          title={language[defaultLanguage].condividi_lista}
        >
          <div className="header-left-content">
            <span className="forward-icon">
              {manager.visibilityConditionShareGrocery(
                list.length > 0 &&
                  !serverResponse.config.hide_share_grocery_list
              ) && manager.leftButtonGroceryComponent(this.props.toggle)}
            </span>
          </div>
          <div
            className="title"
            style={{
              marginLeft:
                manager.buttonCloseGrocery() &&
                !serverResponse.config.hide_share_grocery_list
                  ? ""
                  : "",
            }}
          >
            {grocery_label}
          </div>

          <div className="header-right-content">
            {manager.buttonCloseGrocery() && (
              <CloseCartIcon
                color={"#0008"}
                icon={faTimes}
                onClick={this.props.toggle}
              />
            )}
          </div>
        </PopoverHeader>

        <PopoverBody
          id="wrapper-body"
          style={isMobile ? mobile_style : desktop_style}
        >
          <>
            {/* <Scrollbars
              className="custom-scrollbars"
              autoHide
              style={{
                height: isMobile ? "calc(100% - 175px)" : "50vh",
                marginBottom: "200px",
              }}
            > */}
            {list.length > 0 &&
              serverResponse.config.send_grocery_list_to_market &&
              totPrice < serverResponse.config.grocery_list_min_value && (
                <div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: "15px",
                      paddingBottom: "5px",
                    }}
                  ></div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingBottom: "15px",
                      width: "90%",
                      margin: "auto",
                    }}
                  >
                    Raggiungi una spesa minima di{" "}
                    {serverResponse.config.grocery_list_min_value}€ per poter
                    inviare la tua lista
                  </div>
                </div>
              )}
            <div className="cart-card-wrapper">{list}</div>
            {/* </Scrollbars> */}

            {/*Insert by hand*/}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                position: "relative",
                bottom: "0",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent:
                    list.length > 0 &&
                    serverResponse.config.send_grocery_list_to_market &&
                    totPrice >= serverResponse.config.grocery_list_min_value
                      ? "space-around"
                      : "flex-start",
                  alignItems: "center",
                  backgroundColor: "white",
                }}
              >
                {/* {manager.visibilityConditionShareGrocery(
                  list.length > 0 &&
                    !serverResponse.config.hide_share_grocery_list
                ) && manager.leftButtonGroceryComponent(this.props.toggle)} */}

                {/* {list.length > 0 &&
                  serverResponse.config.send_grocery_list_to_market &&
                  totPrice >= serverResponse.config.grocery_list_min_value && (
                    <div
                      style={{
                        textAlign: "center",
                        paddingTop: "15px",
                        paddingBottom: "15px",
                      }}
                    >
                      <div
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          alignItems: "center",
                          background: "var(--primary-color)",
                          borderRadius: "30px",
                          color: "white",
                          padding: "10px",
                          textDecoration: "none !important",
                          width: "170px",
                          height: "45px",
                        }}
                        onClick={() =>
                          manager.actionCompleteOrder(
                            this.sendGroceryListToMarket
                          )
                        }
                      >
                        <Negozio style={{ width: "35px" }} />
                        <span
                          style={{
                            // textDecoration: "underline",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {
                            language[defaultLanguage][
                              manager.labelCompleteOrder()
                            ]
                          }
                        </span>
                      </div>
                    </div>
                  )} */}
              </div>
              <div className="cart-price-info-wrapper">
                <div className="d-flex align-items-center gap-3">
                  <div className="cart-price">
                    <span className="total-text">
                      {language[defaultLanguage].totale}:
                    </span>
                    <span className="total-price">{totPrice.toFixed(2)} €</span>
                  </div>
                  <RemoveAllBtn
                    active={this.props.groceryList.list.length > 0}
                    deleteAll={() => {
                      this.props.deleteAll();
                      deleteAll(Array.from(this.props.groceryList.list));
                    }}
                  />
                </div>
                {list.length > 0 &&
                  serverResponse.config.send_grocery_list_to_market &&
                  totPrice >= serverResponse.config.grocery_list_min_value && (
                    <div className="order-btn">
                      <button
                        className="btn btn-green"
                        onClick={() =>
                          manager.actionCompleteOrder(
                            this.sendGroceryListToMarket
                          )
                        }
                      >
                        {
                          language[defaultLanguage][
                            manager.labelCompleteOrder()
                          ]
                        }{" "}
                        <CartIcon className="ml-8" />
                      </button>
                    </div>
                  )}
                {/* <h5 style={{ fontWeight: "900" }} id="h5total">
                    {language[defaultLanguage].totale}: € {totPrice.toFixed(2)}
                  </h5>

                  <RemoveAllBtn
                    active={this.props.groceryList.list.length > 0}
                    deleteAll={() => {
                      this.props.deleteAll();
                      deleteAll(Array.from(this.props.groceryList.list));
                    }}
                  /> */}
              </div>
              {false && !serverResponse.config.hide_plus_product ? (
                <div className="cart-modal-bottom-content d-flex flex-column">
                  <h6>{language[defaultLanguage].aggiungi_non_presente}</h6>
                  <div ref={this.addNewProductRef} className="add-product-form">
                    <div className="d-flex product-input">
                      <div className="form-group product-name">
                        <Input
                          type="text"
                          name="product-title"
                          id="product-title"
                          placeholder={language[defaultLanguage].nome_prodotto}
                          value={newProductTitle}
                          onChange={this.handleChangeTitle}
                        />
                      </div>
                      <div className="grocery-product-quantity">
                        <span className="quantity-icon xs-none">
                          <FontAwesomeIcon
                            icon={faMinus}
                            onClick={() => this.handleChangeQuantity(0)}
                          />
                        </span>
                        <span className="d-flex">
                          <h6>{newProductQuantity}</h6>
                          {/* <h6>{grocery_label ? grocery_label : "pz."}</h6> */}
                        </span>
                        <span className="quantity-icon xs-none">
                          <FontAwesomeIcon
                            icon={faPlus}
                            onClick={() => this.handleChangeQuantity(1)}
                          />
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between flex-wrap price-input-wrapper">
                      {!serverResponse.config.hide_plus_product_price ? (
                        <div className="form-group">
                          <Input
                            min={0}
                            type="number"
                            name="product-price"
                            id="product-price"
                            placeholder={
                              language[defaultLanguage].prezzo_stimato
                            }
                            value={newProductPrice}
                            onChange={this.handleChangePrice}
                            onKeyPress={this.handleKeyUp}
                          />
                        </div>
                      ) : null}
                      <div className="save-btn">
                        <button
                          onClick={() => this.saveNewProduct()}
                          className="btn btn-primary-outlined"
                        >
                          aggiungi <SaveIcon />
                        </button>
                      </div>
                    </div>

                    {/* <div className="grocery-product-quantity"> */}
                    {/* <FontAwesomeIcon
                          className="change-product-quantity-icon iconStyleModal iconL "
                          icon={faMinus}
                          style={{ marginRight: "10px" }}
                          onClick={() => this.handleChangeQuantity(0)}
                        />
                        <h6>{newProductQuantity}</h6>
                        <FontAwesomeIcon
                          className="change-product-quantity-icon iconStyleModal iconR"
                          icon={faPlus}
                          style={{ marginLeft: "10px" }}
                          onClick={() => this.handleChangeQuantity(1)}
                        /> */}

                    {/*<FontAwesomeIcon icon={faSave} style={{ color: "#00ff00", marginLeft: "20px" }} onClick={() => this.saveNewProduct()} />*/}
                    {/* <div
                          id="btnSave"
                          src={AddProd}
                          style={{
                            marginLeft: "20px",
                            width: "35px",
                            cursor: "pointer",
                          }}
                          onClick={() => this.saveNewProduct()}
                          alt="img"
                        >
                          {serverResponse.config.send_grocery_list_to_market ? (
                            <AddCarrello />
                          ) : (
                            <AddLista />
                          )}
                        </div>

                        <Tooltip
                          placement="top"
                          isOpen={this.state.saveTooltipOpen}
                          target="btnSave"
                          toggle={this.toggleSaveTooltip}
                        >
                          {strings.saveBtnTooltip}
                        </Tooltip> */}
                    {/* </div> */}
                  </div>
                  {/* </AnimatedBox> */}
                </div>
              ) : null}
              {/* {!serverResponse.config.hide_plus_product ? (
                <div
                  style={{
                    padding: "10px",
                    fontWeight: "bold",
                    background: "white"
                  }}
                  onClick={() => this.showCustomProductOnMobile()}
                >
                  {language[defaultLanguage].inserisci_un_prodotto_non_presente}
                </div>
              ) : null} */}
              {!serverResponse.config.hide_plus_product ? (
                <div id="prodottoNonPresente" style={{ background: "white" }}>
                  <div id="closeIcon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.hideCustomProductOnMobile()}
                    />
                  </div>
                </div>
              ) : null}
            </div>
            {/* fine bottoni */}
          </>
        </PopoverBody>
      </Popover>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    groceryList: state.groceryList,
    authJWT: state.authJWT,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addProduct: (newProduct) => {
      dispatch(actions.addProduct(newProduct));
    },
    deleteAll: () => {
      dispatch(actions.deleteAll());
    },
  };
}

function deleteAll(items) {
  items.forEach((item) => {
    var image = document.getElementById("selected-" + item.item_id);
    if (image != null) {
      image.style["display"] = "none";
    }
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(GroceryList);
