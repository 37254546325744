import React from "react";

import DefaultManager from "./Default.manager";
import FormPrequComponent from "../views/FormPrequ.component";

class PrequManager extends DefaultManager {
  signboardId;

  constructor(signboardId) {
    super(signboardId);
    this.signboardId = signboardId;
  }

  getSignupForm() {
    return <FormPrequComponent />;
  }
}

export default PrequManager;
