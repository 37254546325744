import React, { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import VideoModal from "./components/Video.modal";
import PlusBody from "./PlusBody";
import HatChefBodyMobile from "./HatChefBodyMobile";
import InfoBodyMobile from "./InfoBodyMobile";
import SpecsBodyMobile from "./SpecsBodyMobile";
import CarouselBodyMobile from "./CarouselBodyMobile";
import { defaultLanguage } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";
import { connect } from "react-redux";
import { iconsObj } from "../../../helper/utility";
import AttachmentBody from "./AttachmentBody";
import WorldBodyMobile from "./WorldBodyMobile";

const ContentModalMobile = ({ setActiveTab, modalRedux, scrolling }) => {
  // const {
  //   contentModal: info,
  // } = modalRedux;
  const markers = useSelector((state) => state.modal.otherProps.info.markers);

  const dataByType = React.useMemo(
    () =>
      markers.reduce((acc, marker) => {
        acc[marker.type] = marker;
        return acc;
      }, {}),
    [markers]
  );

  const plusRef = useRef(null);
  const worldRef = useRef(null);
  const playRef = useRef(null);
  const hatChefRef = useRef(null);
  const infoRef = useRef(null);
  const specsRef = useRef(null);
  const attachmentRef = useRef(null);
  const carouselRef = useRef(null);

  const sectionRefs = useMemo(
    () => ({
      plus: plusRef,
      world: worldRef,
      play: playRef,
      "hat-chef": hatChefRef,
      info: infoRef,
      specs: specsRef,
      attachment: attachmentRef,
      carousel: carouselRef,
    }),
    []
  );

  useEffect(() => {
    const handleScroll = () => {
      if (scrolling) return; // Do not activate next section while scrolling

      let activeSection = null;
      const windowHeight = window.innerHeight;
      const bottomOfPage =
        document.documentElement.scrollHeight - window.scrollY <= windowHeight;

      Object.entries(sectionRefs).forEach(([key, ref]) => {
        if (ref.current) {
          const { top, bottom } = ref.current.getBoundingClientRect();

          if (top < windowHeight * 0.5 && bottom > windowHeight * 0.5) {
            activeSection = key;
          }

          if (bottomOfPage && bottom <= windowHeight) {
            activeSection = key;
          }
        }
      });

      if (activeSection) {
        setActiveTab(activeSection);
      }
    };

    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll, true);
  }, [setActiveTab, sectionRefs, scrolling]);

  return (
    <div ref={sectionRefs.plus} className={`complete-modal-body relative`}>
      {dataByType.plus && (
        <section id="plus">
          <PlusBody />
        </section>
      )}
      {dataByType.world && (
        <section id="world" ref={sectionRefs.world}>
          <WorldBodyMobile
            data={dataByType.world.data}
            title={dataByType.world.title}
            icon={iconsObj.world.component}
            type="Link"
          />
        </section>
      )}
      {dataByType.play && (
        <section id="play" ref={sectionRefs.play}>
          <VideoModal
            data={dataByType.play.data}
            title={dataByType.play.title}
            icon={iconsObj.play.component}
            type={language[defaultLanguage].video}
          />
        </section>
      )}
      {dataByType["hat-chef"] && (
        <section id="hat-chef" ref={sectionRefs["hat-chef"]}>
          <HatChefBodyMobile
            data={dataByType["hat-chef"].data}
            title={dataByType["hat-chef"].title}
            icon={iconsObj["hat-chef"].component}
            type={language[defaultLanguage].ricetta}
          />
        </section>
      )}
      {dataByType.info?.data && (
        <section id="info" ref={sectionRefs.info}>
          <InfoBodyMobile
            data={dataByType.info.data}
            icon={iconsObj.info.component}
            type={language[defaultLanguage].curiosita}
          />
        </section>
      )}
      {dataByType.specs && (
        <section id="specs" ref={sectionRefs.specs}>
          <SpecsBodyMobile
            data={dataByType.specs.data}
            title={dataByType.specs.title}
            icon={iconsObj.specs.component}
            type={language[defaultLanguage].scheda}
          />
        </section>
      )}
      {dataByType.attachment && (
        <section id="attachment" ref={sectionRefs.attachment}>
          <AttachmentBody
            data={dataByType.attachment.data}
            title={dataByType.attachment.title}
            icon={iconsObj.attachment.component}
            type={language[defaultLanguage].allegato}
          />
        </section>
      )}
      {dataByType.carousel?.data && (
        <section id="carousel" ref={sectionRefs.carousel}>
          <CarouselBodyMobile
            data={dataByType.carousel.data}
            icon={iconsObj.carousel.component}
            type={language[defaultLanguage].carosello}
          />
        </section>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(ContentModalMobile);
