import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="w-100">
      <iframe
        height="250px"
        width="100%"
        title="privacy policy"
        src="https://www.iubenda.com/privacy-policy/86976632"
      >
        PrivacyPolicy
      </iframe>
    </div>
  );
};

export default PrivacyPolicy;
