var leaflets = null;

var isPreview = window.location.pathname.search("preview") !== -1;
var request_v = new XMLHttpRequest();
var request = new XMLHttpRequest();
var json_leaflet = null;
var v = 1;

if (isPreview) {
  global.preview_id = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const id = urlParams.get("id");

  if (!id) global.preview_id = sessionStorage.getItem("preview_volantino_id");
  else {
    sessionStorage.setItem("preview_volantino_id", id);
    global.preview_id = id;
  }

  request.open(
    "GET",
    `${process.env.REACT_APP_INPUBLISH}/it/interactive-flyer-json/${global.preview_id}`,
    false
  );
  request.send(null);

  try {
    json_leaflet = JSON.parse(request.responseText);
  } catch (e) {
    console.log(e.message);
  }
  leaflets = json_leaflet;
} else {
  var o = window.location.origin;

  var p = window.location.pathname;
  var t = p.split("/");

  var baseUrl = o + "";
  var i = 1;
  t.forEach((e) => {
    if (e !== "" && i <= 2) {
      baseUrl += "/" + e;
      i++;
    }
  });

  if (process.env.NODE_ENV === "production") baseUrl += "/config/";
  else baseUrl = "/config/";

  try {
    request_v.open(
      "GET",
      baseUrl + "version.json?v=" + new Date().getTime(),
      false
    );

    request_v.send(null);
    v = JSON.parse(request_v.responseText).v;
    request.open("GET", baseUrl + "publication.json?v=" + v, false);
    request.send(null);
    json_leaflet = JSON.parse(request.responseText);
    leaflets = json_leaflet;
  } catch (error) {
    console.log(error);
  }
}

const lefletSwitch = () => {
  leaflets.client.config.adsense = false;
  if (isPreview) {
    leaflets.client.config.ga_active = false;
    leaflets.client.config.only_logged = false;
    leaflets.client.config.allow_login = false;
  }

  return leaflets.client;
};

export const serverResponse = lefletSwitch();
