import React from "react";
import ContentModalIndex from "./ContentModalIndex";

const ContentModal = (props) => {
  
  const ComponentToRender = ContentModalIndex[props.type];
  return <ComponentToRender />;
};

export default ContentModal;
