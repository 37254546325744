import React from "react";
import { EmailIcon } from "react-share";
import { EmailShareButton } from "react-share";
import { FacebookShareButton } from "react-share";
import { LinkedinShareButton } from "react-share";
import { LinkedinIcon } from "react-share";
import { TelegramIcon } from "react-share";
import { TelegramShareButton } from "react-share";
// import { TwitterIcon } from "react-share";
import { TwitterShareButton } from "react-share";
import { WhatsappIcon } from "react-share";
import { WhatsappShareButton } from "react-share";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import Row from "reactstrap/lib/Row";
import AdComponent from "../leaflet/Adsense";
import { serverResponse } from "../../util/fakeServer";
import { gaEventLabel } from "../../settings/gaConfig";
import { FacebookIcon } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { NotificationManager } from "react-notifications";
import { styles } from "./shareModal.style";
import { defaultLanguage, sendAnalyticsEvent } from "../../helper/utility";
import { language } from "../../languageProvider/language/language";
import  CustomTwitterIcon  from "../../assets/icons/twitter.icon";


const link = serverResponse.config.shareFlyerURL;
const title = serverResponse.config.shareFlyerTitle;

const ShareModal = ({ open, setOpen }) => {
  const toggle = () => {
    setOpen(false);
  };

  const copyToClip = () => {
    navigator.clipboard
      .writeText(serverResponse.config.shareFlyerURL)
      .then(() =>
        NotificationManager.success(
          "Elemento Copiato",
          "Link Copiato con successo ",
          1000
        )
      )
      .catch((err) => {
        NotificationManager.error(
          "Errore nella copia",
          "Link Non Copiato",
          1000
        );
      });
  };

  const openReactNativeShare = () => {
    const str = title + "    " + link;
    const obj = { action: "whatsapp", value: str };
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(JSON.stringify(obj));
  };

  return (
    <div>
      <Modal
        className="modal_share_custom"
        centered
        isOpen={open}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          {language[defaultLanguage].condividi_con}
        </ModalHeader>
        <ModalBody>
          <div className="custom-share-container" style={{ display: "flex" }}>
            <FacebookShareButton
              className="facebookShareButton shareIcon"
              url={link}
              beforeOnClick={() => {
                sendAnalyticsEvent(gaEventLabel.share_leaflet_facebook);
              }}
            >
              <FacebookIcon size={32} bgStyle={{ fill: "black" }} />
            </FacebookShareButton>

            <WhatsappShareButton
              className="whatsappShareIcon shareIcon"
              style={styles.wa1}
              title={title}
              url={link}
              beforeOnClick={() => {
                sendAnalyticsEvent(gaEventLabel.share_leaflet_whatsapp);
              }}
            >
              <WhatsappIcon size={32} bgStyle={{ fill: "black" }} />
            </WhatsappShareButton>

            <span style={styles.wa2} onClick={() => openReactNativeShare()}>
              <WhatsappIcon size={32} />
            </span>

            <TelegramShareButton
              className="telegramShareIcon shareIcon"
              title={title}
              url={link}
              beforeOnClick={() => {
                sendAnalyticsEvent(gaEventLabel.share_leaflet_telegram);
              }}
            >
              <TelegramIcon size={32} bgStyle={{ fill: "black" }} />
            </TelegramShareButton>

            <EmailShareButton
              className="emailShareIcon shareIcon"
              url={link}
              subject={title}
              openWindow={false}
              beforeOnClick={() => {
                sendAnalyticsEvent(
                  gaEventLabel.share_leaflet_mail,
                  String(this.props.info.item_id)
                );
              }}
            >
              <EmailIcon size={32} bgStyle={{ fill: "black" }} />
            </EmailShareButton>

            <LinkedinShareButton
              className="linkedinShareIcon shareIcon"
              title={title}
              url={link}
              beforeOnClick={() => {
                sendAnalyticsEvent(gaEventLabel.share_leaflet_telegram);
              }}
            >
              <LinkedinIcon size={32} bgStyle={{ fill: "black" }} />
            </LinkedinShareButton>

            <TwitterShareButton
              className="twitterShareIcon shareIcon"
              title={title}
              url={link}
              beforeOnClick={() => {
                sendAnalyticsEvent(gaEventLabel.share_leaflet_twitter);
              }}
            >
              <CustomTwitterIcon size={32} bgStyle={{ fill: "black" }} />
            </TwitterShareButton>

            <div
              className="copyButtonVolantino shareIcon"
              onClick={copyToClip}
              style={styles.copyButton}
            >
              <FontAwesomeIcon
                style={{ margin: 0 }}
                color="white"
                size="sm"
                icon={faCopy}
              />
            </div>
          </div>
        </ModalBody>
        {serverResponse.config.adsense && (
          <ModalFooter>
            <AdComponent />
          </ModalFooter>
        )}
      </Modal>
    </div>
  );
};

export default ShareModal;
