import React, {
  Component,
  // createRef
} from "react";
import Product from "../productsList/Product";

import { connect } from "react-redux";
import Cookies from "universal-cookie";
import {
  InputGroup,
  Input,
  // Button,
  // Label,
  // Row,
  // Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   // faFilter,
//   faArrowUp
// } from "@fortawesome/free-solid-svg-icons";

import "../../assets/css/custom.css";
import { serverResponse } from "../../util/fakeServer";
import withParams from "../common/WithParams";
import { gaEventLabel } from "../../settings/gaConfig";
import { isIE, isEdge } from "react-device-detect";
import OffCanvas from "react-aria-offcanvas";
import { styles } from "./categories.styles";
import Adsense from "../leaflet/Adsense";
import { language } from "../../languageProvider/language/language";
import {
  defaultLanguage,
  getCustomActions,
  sendAnalyticsEvent,
} from "../..//helper/utility";
// import Select from "react-select";
import { getPricesAction } from "../../redux/price/price.action";
// import searchPageLogo from "../../assets/images/searchpagelogo.png";
import { BREAKPOINT } from "../../helper/constant";
import SearchFilter from "../../assets/icons/searchFilter.icon";
import "../../assets/css/categorystatic.css";
import { Link } from "react-router-dom";
import BackArrowIcon from "../../assets/icons/backarrow-icon";
import { Search } from "../../assets/images/icone";
import ManagerFactory from "../../managers/ManagerFactory";
import { openInteractivityModal } from "../../redux/modal/action";

const manager = ManagerFactory.getManager();

const DEFAULT_ITEMS = 12;

if (
  serverResponse.config.only_browsable ||
  serverResponse.config.hide_product_page
) {
  var path = window.location.pathname;
  if (path === "/categories") {
    window.location.href = "/leaflet";
  }
}

class CategoriesStatic extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.selectCategoryRef = React.createRef();
    this.selectSubCategoryRef = React.createRef();

    let page_has_video = false;
    let video_only_once = false;
    let video_mandatory_seconds = 0;
    let videos = [];
    let video_never = false;

    if (serverResponse.hasOwnProperty("video_categories")) {
      page_has_video = true;
      videos = serverResponse.video_categories.videos.map((i) => i.page);
      video_only_once = serverResponse.video_categories.show_only_once;
      video_mandatory_seconds =
        serverResponse.video_categories.mandatory_seconds;
    }

    const cookies = new Cookies();

    if (cookies.get("neverTutorial") !== undefined) video_never = true;
    this.state = {
      products: Object.values(serverResponse.interactivities).filter(
        (el) => el.type === "product"
      ),
      tags:
        serverResponse.leaflet && serverResponse.leaflet.tags
          ? Object.values(serverResponse.leaflet.tags)
          : [],
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      items: 0,
      hasMoreItems: true,
      showScrollUp: false,
      page_has_video: page_has_video,
      videos: videos,
      show_close_video: false,
      preroll_video_in_progress: false,
      video_only_once: video_only_once,
      video_page_number: null,
      video_page_item_id: null,
      countdown: video_mandatory_seconds,
      video_mandatory_seconds: video_mandatory_seconds,
      video_modal_open: false,
      video_url: "",
      video_muted: true,
      video_playing: false,
      video_never: video_never,
      loading: false,
      numberOfItem: null,
      sidebarFilterOpen: false,
      listType: "grid",
      subCategoryArr: [],
      itemsToRender: [],
      skulsArr: [],
      totalProduct: 0,
      globalHeigth: window.innerHeight,
      globalWidth: window.innerWidth,
      isFirstTime: true,
      featuredCategories: [],
      featuredProducts: {},
      featuredItemRender: true,
      itemsRender: false,
      selectedTags: new Set(),
      tagsItemRender: false,
      activeChevronCategory: null,
      arrowButtonClick: false,
      activeCategory: null,
      showSubCategories: false,
      categoryLogo: "",
    };
  }

  backToCategories = () => {
    this.setState({
      showSubCategories: false,
      activeCategory: null,
    });
  };

  handleTagClick = (tagId) => {
    this.setState((prevState) => {
      const newSelectedTags = new Set(prevState.selectedTags);
      if (newSelectedTags.has(tagId)) {
        newSelectedTags.delete(tagId);
      } else {
        newSelectedTags.add(tagId);
      }

      let showFeatured =
        newSelectedTags.size === 0 &&
        !prevState.categoryFilter &&
        !prevState.subCategoryFilter;
      let renderItemsBasedOnFilters =
        newSelectedTags.size === 0 &&
        (prevState.categoryFilter || prevState.subCategoryFilter);

      return {
        selectedTags: newSelectedTags,
        featuredItemRender: showFeatured,
        tagsItemRender: newSelectedTags.size > 0,
        itemsRender: renderItemsBasedOnFilters,
      };
    });
    this.setState({ sidebarFilterOpen: false, nameFilter: "" });
  };

  cancelTagsFilter = () => {
    this.setState({
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      items: DEFAULT_ITEMS,
      hasMoreItems: true,
      tagsItemRender: false,
      selectedTags: new Set(),
      featuredItemRender: true,
      categoryFilter: "",
      subCategoryFilter: "",
      itemsRender: false,
      activeChevronCategory: null,
    });
  };

  setupFeaturedCategories() {
    const products = Object.values(serverResponse.interactivities).filter(
      (el) => el.type === "product"
    );
    let { featured_categories } = serverResponse.leaflet;
    let categoryIds = new Set(products.map((product) => product.category_id));

    let featured;
    if (featured_categories?.length > 0) {
      featured = featured_categories;
    } else {
      let shuffledCategoryIds = Array.from(categoryIds);
      shuffledCategoryIds.sort(() => 0.5 - Math.random());
      featured = shuffledCategoryIds.slice(0, 3);
    }

    this.setState(
      { featuredCategories: featured },
      this.fetchRandomProductsForFeaturedCategories
    );
  }

  fetchRandomProductsForFeaturedCategories() {
    const products = Object.values(serverResponse.interactivities).filter(
      (el) => el.type === "product"
    );
    let featuredProducts = {};

    this.state.featuredCategories.forEach((categoryId) => {
      const filteredProducts = products.filter(
        (product) => product.category_id === categoryId
      );
      featuredProducts[categoryId] = this.getRandomProducts(
        filteredProducts,
        4
      );
    });

    this.setState({ featuredProducts });
  }

  getRandomProducts(products, count) {
    let shuffledProducts = [...products];
    for (let i = shuffledProducts.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledProducts[i], shuffledProducts[j]] = [
        shuffledProducts[j],
        shuffledProducts[i],
      ];
    }
    return shuffledProducts.slice(0, count);
  }

  getCategoryName(categoryId) {
    const categoriesArray = Object.values(
      serverResponse.interactivities
    ).filter((el) => el.type === "product");
    const category = categoriesArray.find(
      (cat) => cat.category_id === categoryId
    );

    return category ? category.category : "N/D";
  }

  getCategoryLogo(categoryId) {
    const categoriesArray = Object.values(serverResponse.leaflet.categories);
    const categoryLogo = categoriesArray.find((cat) => cat.id === categoryId);

    return categoryLogo ? categoryLogo.category_logo : null;
  }

  openBanner = () => {
    const { config, interactivities } = serverResponse;

    // Gestione clic banner interno
    if (config.internal_banner_click && config.product_banner) {
      const interact = interactivities[config.product_banner];

      if (interact) {
        const info = getCustomActions().getInteractivity(config.product_banner);

        if (info) {
          this.props.openInteractivityModal(
            { info, type: "plus" },
            config.product_banner
          );
        }
      }

      return; // Uscita anticipata se non esiste interactivity
    }

    // Gestione clic banner esterno
    if (config.external_banner_click && config.href_banner) {
      let href = config.href_banner;

      // Se href non contiene "http://" o "https://", aggiungilo manualmente
      if (!/^https?:\/\//i.test(href)) {
        href = `https://${href}`;
      }

      window.open(href, "_blank");
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.resize);
    if (this.props.params.name !== undefined) {
      this.setState({ nameFilter: this.props.match.params.name });
    }

    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.xs
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    scrollTarget.addEventListener("scroll", this.trackScrolling);

    // if (serverResponse.config.ga_active) {
    //   ReactGA.pageview(
    //     `/${serverResponse.config.client_id}-${serverResponse.leaflet.id}/categorie`
    //   );
    // }

    if (this.state.page_has_video && !this.state.video_never) {
      this.showPageVideo(-1, true);
    }
    if (this.props.params.item_id !== undefined) {
      if (document.getElementById("plus-" + this.props.match.params.item_id))
        document
          .getElementById("plus-" + this.props.match.params.item_id)
          .click();
    }
    if (this.props.params.item_id !== undefined) {
      this.setState({ items: this.state.products.length });
    }
    this.setupFeaturedCategories();
  }
  componentWillUnmount() {
    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.xs
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");
    scrollTarget.removeEventListener("scroll", this.trackScrolling);
  }

  resize = () => {
    this.setState({
      globalHeigth: window.innerHeight,
    });
    this.setState({ globalWidth: window.innerWidth });
  };

  handleTextualSearch = () => {
    if (this.state.nameFilter.length > 2) {
      this.setState(
        {
          isFirstTime: false,
          items: 0,
          itemsRender: true,
          featuredItemRender: false,
          itemsToRender: [],
          categoryFilter: "",
          subCategoryFilter: "",
          selectedTags: new Set(),
          activeChevronCategory: null,
          showSubCategories: false,
        },
        () => this.updateProducts()
      );
      // setTimeout(() => {
      //   // document.getElementById("scroller").scrollIntoView();
      //   if (this.myRef && this.myRef.current) {
      //     //  this.myRef.current.scrollIntoView({ behavior: "smooth" });
      //   }
      // }, 0);
    }
  };

  handleChangeName = (e) => {
    const newSearchValue = e.target.value;
    const trimmedFilter = newSearchValue.replace(/\s/g, "");
    this.setState(
      {
        nameFilter: newSearchValue,
        items: [],
        hasMoreItems: true,
      },
      () => {
        if (trimmedFilter.length === 0) {
          this.cancelTagsFilter();
        } else if (trimmedFilter.length <= 2) {
          setTimeout(() => {
            if (isIE || isEdge) {
              document.getElementById("scrollable-div").scrollTop = 0;
            } else {
              document.getElementById("scrollable-div").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
            if (this.myRef && this.myRef.current) {
              this.myRef.current.scrollIntoView({ behavior: "smooth" });
            }
          }, 0);
        } else {
          this.handleTextualSearch();
        }
      }
    );
  };

  handleChangeBrand = (e) => {
    this.setState({
      brandFilter: e.target.checked,
      items: [],
      hasMoreItems: true,
    });
    document.getElementById("scrollable-div").scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  handleChangeCategory = (value, logo) => {
    this.setState(
      {
        categoryFilter: value,
        categoryLogo: logo,
        subCategoryFilter: "",
        subCategoryArr: serverResponse.leaflet.categories.find(
          (e) => e.category_name === value
        ).subcategories,
        items: DEFAULT_ITEMS,
        hasMoreItems: true,
      },
      () => {
        !this.state.arrowButtonClick &&
          this.handleSearch() &&
          this.setState({ nameFilter: "" });
      }
    );

    // this.myRef.current.scrollTo(0, 0);
    setTimeout(() => {
      if (isIE || isEdge) {
        document.getElementById("scrollable-div").scrollTop = 0;
      } else {
        document.getElementById("scrollable-div").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }

      if (this.myRef && this.myRef.current) {
        this.myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  handleChangeCategoryMobile = (value, logo) => {
    this.setState(
      {
        categoryFilter: value,
        categoryLogo: logo,
        subCategoryFilter: "",
        subCategoryArr: serverResponse.leaflet.categories.filter(
          (e) => e.category_name === value
        )[0].subcategories,
        items: DEFAULT_ITEMS,
        hasMoreItems: true,
        // showSubCategories: true,
      },
      () => {
        !this.state.arrowButtonClick &&
          this.handleSearch() &&
          this.setState({ nameFilter: "" });
      }
    );
  };

  handleChangeSubCategory = (value) => {
    this.setState(
      {
        subCategoryFilter: value,
        items: DEFAULT_ITEMS,
        hasMoreItems: true,
        nameFilter: "",
      },
      () => this.handleSearch()
    );
    setTimeout(() => {
      if (this.myRef && this.myRef.current) {
        this.myRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };
  handleChangeSubCategoryMobile = (value) => {
    this.setState(
      {
        subCategoryFilter: value,
        items: DEFAULT_ITEMS,
        hasMoreItems: true,
        nameFilter: "",
      },
      () => this.handleSearch()
    );
    // this.myRef.current.scrollTo(0, 0);
    setTimeout(() => {
      if (isIE || isEdge) {
        document.getElementById("scrollable-div").scrollTop = 0;
      } else {
        document.getElementById("scrollable-div").scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    }, 0);
  };

  productFilter = (product) => {
    let nameCheck = false;
    let categoryCheck = false;
    let subcategoryCheck = false;
    // if (product.skul) {
    nameCheck = String(product.description)
      .toLowerCase()
      .includes(this.state.nameFilter.toLowerCase())
      ? true
      : false;

    categoryCheck =
      this.state.categoryFilter !== ""
        ? product.category?.toLowerCase() ===
          this.state.categoryFilter.toLowerCase()
          ? true
          : false
        : true;
    subcategoryCheck =
      this.state.subCategoryFilter !== ""
        ? product.subcategory?.toLowerCase() ===
          this.state.subCategoryFilter.toLowerCase()
          ? true
          : false
        : true;
    const tagCheck =
      this.state.selectedTags.size > 0
        ? product.tags?.some((tag) => this.state.selectedTags.has(tag))
        : true;
    // }
    return nameCheck && categoryCheck && subcategoryCheck && tagCheck;
  };

  resetFilters = () => {
    this.setState({
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      subCategoryArr: [],
      items: [],
      hasMoreItems: true,
      skulsArr: [],
      itemsToRender: [],
      isFirstTime: true,
      activeChevronCategory: null,
    });

    this.selectCategoryRef.current.clearValue();
    this.selectSubCategoryRef.current.clearValue();

    // document.getElementById("scrollable-div").scrollTop = 0;
    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
  };

  resetFiltersMobile = () => {
    this.closeFilterBar();
    this.setState({
      nameFilter: "",
      brandFilter: false,
      underpriceFilter: false,
      categoryFilter: "",
      subCategoryFilter: "",
      items: DEFAULT_ITEMS,
      hasMoreItems: true,
      itemsRender: false,
      featuredItemRender: true,
      selectedTags: new Set(),
      tagsItemRender: false,
      activeChevronCategory: null,
    });

    document.getElementById("product_").value = "";

    setTimeout(() => {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }, 0);
    if (isIE || isEdge) {
      document.getElementById("scrollable-div").scrollTop = 0;
    } else {
      document.getElementById("scrollable-div").scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
  };

  formatCat = (obj) => {
    let opt = [];
    obj.arr.map((element) =>
      opt.push({
        value: obj.key ? element[obj.key] : element,
        label: obj.key ? element[obj.key] : element,
      })
    );
    return opt;
  };

  handleSearch = () => {
    if (
      this.state.categoryFilter !== "" ||
      this.state.subCategoryFilter !== "" ||
      this.state.nameFilter.replace(/\s/g, "") !== ""
    )
      this.setState(
        {
          isFirstTime: false,
          items: 0,
          itemsToRender: [],
          nameFilter: "",
        },
        () => this.updateProducts()
      );
    this.setState({ itemsRender: true, featuredItemRender: false });
  };

  updateProducts = () => {
    this.setState(
      {
        items: this.state.items + DEFAULT_ITEMS,
      },
      () => {
        let filteredProducts = this.state.products
          .filter((el) => !el.dynamic_info)
          .filter((i) => i.markers.filter((m) => m.type === "plus").length > 0)
          .filter((el) => el.skul !== undefined)
          .filter(this.productFilter);

        this.setState(
          {
            totalProduct: filteredProducts.length,
            itemsToRender: filteredProducts.slice(0, this.state.items),
            sidebarFilterOpen: false,
          },
          () => this.retrieveSkuls(this.state.itemsToRender)
        );
      }
    );
  };

  retrieveSkuls = (arr, cb) => {
    let startFrom = this.state.skulsArr.length;
    this.setState(
      {
        skulsArr: arr.filter((el) => el.dynamic_price).map((el) => el.skul),
      },
      () => {
        //REDUX

        this.props.getPricesAction(
          "",
          false,
          this.state.skulsArr.slice(startFrom, this.state.skulsArr.length)
        );
      }
    );
  };

  classChangeClick(categoryName) {
    const isSame = this.state.activeChevronCategory === categoryName;
    if (isSame) {
      return;
    }
    this.setState((prevState) => ({
      activeChevronCategory:
        prevState.activeChevronCategory === categoryName ? null : categoryName,
    }));
  }

  isBottom(el) {
    return el.scrollHeight - el.scrollTop - el.clientHeight < 1;
  }

  trackScrolling = () => {
    const scrollTarget =
      this.state.globalWidth >= BREAKPOINT.xs
        ? document.querySelector(".wrapper_vm_container").parentNode
        : document.querySelector("#scrollable-div");

    if (this.isBottom(scrollTarget)) {
      if (this.state.itemsToRender.length < this.state.totalProduct)
        this.updateProducts();

      document.removeEventListener("scroll", this.trackScrolling);
    }
  };

  closeFilterBar = () => {
    this.setState({
      categoryFilter: "",
      sidebarFilterOpen: false,
    });
  };

  render() {
    const {
      nameFilter,
      //  subCategoryArr,
      // showScrollUp
    } = this.state;

    let subcategories = [];
    if (this.state.categoryFilter !== "") {
      serverResponse.leaflet.categories.map((element) => {
        if (element.category_name === this.state.categoryFilter) {
          subcategories = element.subcategories;
        }
        return false;
      });
    }

    let brandInput = "";
    let underpriceInput = "";
    let amostOneCustomCheckbox = false;

    const filtri = (
      <>
        <div className="row ma-0 d-none d-sm-block">
          <div className="roe-card-body">
            <div
              className={`sidebarMenuBlock ${
                !("left_bar" in serverResponse.config) ||
                (!serverResponse.config.left_bar && "filterleft")
              }`}
            >
              <div
                className="theme-brand text-center"
                style={{ textAlign: "center" }}
              >
                <img
                  className="img-fluid"
                  style={{
                    width: "100px",
                  }}
                  src={serverResponse.config.logo_full}
                  alt=""
                />
              </div>
              <div className="sidebarMenuBlock__item">
                <InputGroup>
                  {/* <InputGroupText>
                      <span className="input-group-text icon-input-group">
                        <FontAwesomeIcon icon={faSearch} />
                      </span>
                    </InputGroupText> */}
                  <Search />
                  <Input
                    placeholder={language[defaultLanguage].prodotto}
                    id="product_"
                    onChange={this.handleChangeName}
                    value={nameFilter}
                  />
                </InputGroup>
              </div>
              <div className="sidebarMenuBlock__item">
                <button
                  className="btn btn-reset d-none d-sm-block"
                  onClick={() => this.resetFiltersMobile()}
                  disabled={
                    this.state.nameFilter === "" &&
                    this.state.brandFilter === false &&
                    this.state.underpriceFilter === false &&
                    this.state.categoryFilter === "" &&
                    this.state.subCategoryFilter === ""
                  }
                >
                  {language[defaultLanguage].reset_filtri}
                </button>
              </div>
              <div
                className="product-category-block"
                style={styles.categoryContainer}
              >
                {serverResponse.leaflet.categories.map((element, key) => {
                  return (
                    <div
                      className="mb-1"
                      key={`${element.category_name}_${key}`}
                    >
                      <div className="product-block-inner">
                        <p
                          className={`pointer product-item-list m-0 ${
                            this.state.categoryFilter ===
                              element.category_name && "active"
                          }`}
                          style={styles.categoryName(
                            this.state.categoryFilter === element.category_name
                              ? serverResponse.config.secondary_color
                              : "black"
                          )}
                          value={element.category_name}
                        >
                          {element.category_logo && (
                            <img
                              src={element.category_logo}
                              alt={element.category_name}
                              style={{ width: "30px", aspectRatio: "1" }}
                            />
                          )}
                          <span
                            onClick={() => {
                              this.handleChangeCategory(
                                element.category_name,
                                element.category_logo
                              );
                              this.classChangeClick(element.category_name);
                              this.setState({ arrowButtonClick: false });
                            }}
                          >
                            {" "}
                            {element.category_name}
                          </span>
                        </p>
                        <span
                          className={`${
                            this.state.activeChevronCategory ===
                            element.category_name
                              ? "rotate font-icon"
                              : "font-icon"
                          }`}
                          onClick={() => {
                            this.classChangeClick(element.category_name);
                            this.handleChangeCategory(
                              element.category_name,
                              element.category_logo
                            );
                            this.setState({ arrowButtonClick: true });
                          }}
                        >
                          <svg
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="chevron-left"
                            className="svg-inline--fa fa-chevron-left "
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 320 512"
                          >
                            <path
                              fill="currentColor"
                              d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      {element.category_name === this.state.categoryFilter &&
                        subcategories.map((subc, key) => {
                          return (
                            <p
                              className={`pointer ${
                                element.category_logo && "subcat_margin"
                              } ${
                                this.state.subCategoryFilter === subc &&
                                "active"
                              }`}
                              style={styles.subCategoryName(
                                this.state.subCategoryFilter === subc
                                  ? serverResponse.config.secondary_color
                                  : "black"
                              )}
                              key={`${subc}_${key}`}
                              value={subc}
                              onClick={() => {
                                this.handleChangeSubCategory(subc);
                                this.setState({ arrowButtonClick: false });
                              }}
                            >
                              {subc}
                            </p>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
              {/* <div className="sidebarMenuBlock__item">
              <InputGroup>
                <Button
                  style={{ marginLeft: "auto" }}
                  color="primary"
                  onClick={this.resetFilters}
                >
                  {language[defaultLanguage].reset}
                </Button>
              </InputGroup>
            </div> */}
            </div>
            {amostOneCustomCheckbox && (
              <div>
                <div>
                  {brandInput}
                  {underpriceInput}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="sidebarMenuBlockMobile">
          <div className="sidebarMenuBlockMobile-item">
            <div className="search-field">
              <input
                type="text"
                className="form-control"
                placeholder="cerca un prodotto"
                id="product_"
                onChange={this.handleChangeName}
                value={nameFilter}
              />
              <Search />
            </div>

            <button
              className="btn-filter"
              onClick={() => {
                this.setState({
                  sidebarFilterOpen: true,
                });
              }}
            >
              <SearchFilter />
            </button>
          </div>
        </div>
      </>
    );

    return (
      <div ref={this.myRef}>
        {filtri}

        <div
          id="scrollable-div"
          className="container-fluid productCategory-content"
          style={{
            overflow: "auto",

            marginTop:
              serverResponse.config.category_banner === "" ||
              !serverResponse.config.category_banner
                ? "0px"
                : "px",
          }}
          onScroll={(e) => {
            if (e.target.scrollTop > 300) {
              if (!this.state.showScrollUp) {
                this.setState({
                  showScrollUp: true,
                });
              }
            } else {
              if (this.state.showScrollUp) {
                this.setState({
                  showScrollUp: false,
                });
              }
            }
          }}
        >
          {/* {isMobile && filtri} */}
          {/* SIDEBAR FILTRI SOLO MOBILE */}
          <OffCanvas
            position="bottom"
            className="d-block d-lg-none sidebar-mobile"
            style={styles.sidebarContainer}
            isOpen={this.state.sidebarFilterOpen}
            onClose={() => {
              this.setState({
                sidebarFilterOpen: false,
              });
            }}
            labelledby="menu-button"
          >
            <div className="categories-static mobile-view">
              {this.state?.tags && this.state?.tags.length > 0 && (
                <div className="categories-static-header">
                  <div className="tag-block">
                    {this.state?.tags.map((tag) => (
                      <div
                        key={tag.id}
                        className={`tag-block-item ${
                          this.state.selectedTags.has(tag.id) ? "selected" : ""
                        }`}
                        onClick={() => this.handleTagClick(tag.id)}
                      >
                        <img src={tag.immagine} alt={tag.nome} />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="modelClose">
                {manager.closeButtonIcon() && (
                  <FontAwesomeIcon
                    onClick={() => this.closeFilterBar()}
                    icon={faTimes}
                    color="#0D0040"
                    size="lg"
                    className="cursor-pointer"
                  />
                )}
              </div>
            </div>

            <div
              className="product-category-block"
              style={styles.categoryContainer}
            >
              {serverResponse.leaflet.categories.map((element, key) => {
                return (
                  <div className="" key={`${element.category_name}_${key}`}>
                    <p
                      className={`pointer product-item-list ${
                        this.state.categoryFilter === element.category_name &&
                        "active"
                      }`}
                      style={styles.categoryName(
                        this.state.categoryFilter === element.category_name
                          ? serverResponse.config.secondary_color
                          : "black"
                      )}
                      value={element.category_name}
                    >
                      {element.category_logo && (
                        <img
                          src={element.category_logo}
                          alt={element.category_name}
                          style={{ width: "30px", aspectRatio: "1" }}
                        />
                      )}
                      <span
                        onClick={() => {
                          this.handleChangeCategoryMobile(
                            element.category_name,
                            element.category_logo
                          );
                          this.setState({ arrowButtonClick: false });
                        }}
                      >
                        {" "}
                        {element.category_name}
                      </span>
                      <span
                        className="font-icon ms-auto"
                        onClick={() => {
                          this.handleChangeCategoryMobile(
                            element.category_name,
                            element.category_logo
                          );
                          this.setState({
                            arrowButtonClick: true,
                            showSubCategories: true,
                          });
                        }}
                      >
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-left"
                          className="svg-inline--fa fa-chevron-left "
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="currentColor"
                            d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
                          ></path>
                        </svg>
                      </span>
                    </p>
                    {/* {element.category_name === this.state.categoryFilter &&
                      subcategories.map((subc, key) => {
                        return (
                          <p
                            className={`pointer ${
                              this.state.subCategoryFilter === subc && "active"
                            }`}
                            style={styles.subCategoryName(
                              this.state.subCategoryFilter === subc
                                ? serverResponse.config.secondary_color
                                : "black"
                            )}
                            key={`${subc}_${key}`}
                            value={subc}
                            onClick={() => {
                              this.handleChangeSubCategoryMobile(subc);
                            }}
                          >
                            {subc}
                          </p>
                        );
                      })} */}
                  </div>
                );
              })}
            </div>
            <div
              className={`category-block-inner ${
                this.state.showSubCategories ? "active" : ""
              }`}
            >
              <div className="category-inner-box">
                <Link className="back" onClick={this.backToCategories}>
                  <BackArrowIcon />
                  tutte le categorie
                </Link>
                <h2>
                  {this.state.categoryLogo && (
                    <img
                      src={this.state.categoryLogo}
                      alt=""
                      style={{ width: "30px", aspectRatio: "1" }}
                    />
                  )}
                  {this.state.categoryFilter}
                </h2>
                {serverResponse.leaflet.categories.map((element, key) => {
                  return (
                    <div
                      className={`category-name ${
                        this.state.categoryLogo ? "ml-46" : "ml-11"
                      }`}
                      key={`${element.category_name}_${key}`}
                    >
                      {element.category_name === this.state.categoryFilter &&
                        subcategories.map((subc, key) => {
                          return (
                            <p
                              className={`pointer ${
                                this.state.subCategoryFilter === subc &&
                                "active"
                              }`}
                              style={styles.subCategoryName(
                                this.state.subCategoryFilter === subc
                                  ? serverResponse.config.secondary_color
                                  : "black"
                              )}
                              key={`${subc}_${key}`}
                              value={subc}
                              onClick={() => {
                                this.handleChangeSubCategoryMobile(subc);
                                this.setState({ arrowButtonClick: false });
                              }}
                            >
                              {subc}
                            </p>
                          );
                        })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="mt-4 d-flex justify-content-around flex-column">
              <button
                className="d-sm-block btn"
                style={{
                  borderRadius: "30px",
                  border: "1px solid #0008",
                  width: "100%",
                  marginBottom: "15px",
                }}
                onClick={() => this.resetFiltersMobile()}
                // disabled={
                //   this.state.nameFilter === "" &&
                //   this.state.brandFilter === false &&
                //   this.state.underpriceFilter === false &&
                //   this.state.categoryFilter === "" &&
                //   this.state.subCategoryFilter === ""
                // }
              >
                {language[defaultLanguage].reset_filtri}
              </button>
            </div>
          </OffCanvas>

          {serverResponse.config.category_banner && (
            <div className="col-sm-12 d-none d-sm-flex text-center">
              <img
                className="product-banner-block"
                alt="banner"
                onClick={this.openBanner}
                src={serverResponse.config.category_banner}
              />
            </div>
          )}
          {this.state?.tags && this.state?.tags.length > 0 && (
            <div className="categories-static">
              <div className="categories-static-header">
                <div className="tag-block">
                  {this.state?.tags.map((tag) => (
                    <div
                      key={tag.id}
                      className={`tag-block-item ${
                        this.state.selectedTags.has(tag.id) ? "selected" : ""
                      }`}
                      onClick={() => this.handleTagClick(tag.id)}
                    >
                      <img src={tag.immagine} alt={tag.nome} />
                    </div>
                  ))}
                </div>
                <button className="btn" onClick={() => this.cancelTagsFilter()}>
                  cancella filtri
                </button>
              </div>
            </div>
          )}
          {!this.state.itemsRender &&
            !this.state.tagsItemRender &&
            serverResponse.config.category_banner_mobile && (
              <div className="col-sm-12 d-flex d-sm-none">
                <img
                  className="product-banner-block"
                  alt="banner"
                  onClick={this.openBanner}
                  src={serverResponse.config.category_banner_mobile}
                  style={{ cursor: "pointer", maxWidth: "100%" }}
                />
              </div>
            )}
          {serverResponse.config.adsense && <Adsense />}
          <div
            id="scroller"
            // style={{ paddingBottom: "80px" }}
            className="row filtered-products justify-content-center"
          >
            {!this.state.tagsItemRender && this.state.itemsRender && (
              <div className="row categories-static-inner p-0   ">
                <div className="categories-static">
                  <h2>
                    <span>
                      <span>
                        {this.state.categoryLogo && (
                          <img
                            src={this.state.categoryLogo}
                            alt=""
                            style={{ width: "30px", aspectRatio: "1" }}
                          />
                        )}
                      </span>
                      <span class="ms-2">
                        {this.state.categoryFilter}{" "}
                        {this.state.subCategoryFilter
                          ? ` - ${this.state.subCategoryFilter}`
                          : null}
                      </span>
                    </span>
                  </h2>
                </div>
                {this.state.itemsToRender.map((element, key) => (
                  <div
                    key={`${element.id}_${key}`}
                    className="col-6 col-sm-6 col-lg-6 col-xl-3 col-xxxl-2 h-full product-item-block-wrap"
                  >
                    <Product
                      fromProducts={true}
                      elementIndex={key}
                      dynamic_price={element.dynamic_price}
                      dynamic_info={element.dynamic_info}
                      info={getCustomActions().getInteractivity(
                        element.item_id
                      )}
                      groceryList={this.props.groceryList}
                    />
                  </div>
                ))}
              </div>
            )}
            {this.state.featuredItemRender && (
              <div className="categories-static">
                {Object.entries(this.state.featuredProducts).map(
                  ([categoryId, products]) => (
                    <div key={categoryId}>
                      <h2>
                        <span>
                          <span>
                            {this.getCategoryLogo(categoryId) && (
                              <img
                                src={this.getCategoryLogo(categoryId)}
                                alt=""
                                style={{ width: "30px", aspectRatio: "1" }}
                              />
                            )}
                          </span>
                          <span className="ms-2">
                            {this.getCategoryName(categoryId)}
                          </span>
                        </span>
                        {categoryId !== "null" && (
                          <button
                            onClick={() =>
                              this.handleChangeCategory(
                                this.getCategoryName(categoryId)
                              )
                            }
                          >
                            mostra tutti
                          </button>
                        )}
                      </h2>
                      <div className="row categories-static-inner  ">
                        {products.map((product, index) => (
                          <div
                            className="col-6 col-sm-6 col-lg-6 col-xl-3 col-xxxl-2 h-full product-item-block-wrap"
                            key={index}
                          >
                            <Product
                              key={product.item_id}
                              info={product}
                              groceryList={this.props.groceryList}
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )
                )}
              </div>
            )}
            {this.state.tagsItemRender && (
              <div className="row categories-static-inner p-0   ">
                {this.state.products
                  .filter(this.productFilter)
                  .map((product, key) => (
                    <div
                      key={`${product.id}_${key}`}
                      className="col-6 col-sm-6 col-lg-6 col-xl-3 col-xxxl-2 h-full product-item-block-wrap"
                    >
                      <Product
                        fromProducts={true}
                        info={product}
                        groceryList={this.props.groceryList}
                      />
                    </div>
                  ))}
              </div>
            )}
          </div>
          {this.state.itemsToRender.length === 0 && !this.state.isFirstTime && (
            <div className="col-12 custom-padding" style={{ padding: "5px" }}>
              <h3 className="noProducts">
                {
                  language[defaultLanguage]
                    .nessun_prodotto_trovato_per_i_parametri_di_ricerca_inseriti
                }
              </h3>
            </div>
          )}
        </div>

        {/* <div
          className="scroll-up-btn"
          style={styles.buttonScrollUpBtn(
            showScrollUp ? "flex" : "none",
            "white"
          )}
          onClick={() => {
            if (isIE || isEdge) {
              document.getElementById("scrollable-div").scrollTop = 0;
            } else {
              document.getElementById("scrollable-div").scroll({
                top: 0,
                left: 0,
                behavior: "smooth",
              });
            }
          }}
          id="arrowScrollTop"
        >
          <FontAwesomeIcon
            size="1x"
            style={styles.displayType(!showScrollUp && "none")}
            icon={faArrowUp}
          />
        </div> */}

        {/* <div
          onClick={() => {
            this.setState({
              sidebarFilterOpen: true,
            });
          }}
          className="filter-scroll-up d-flex d-lg-none shadow"
          style={styles.filterScrollUp(
            serverResponse.config.secondary_color,
            showScrollUp ? "10" : "10"
          )}
        >
          <FontAwesomeIcon
            onClick={() => {
              this.setState({
                sidebarFilterOpen: true,
              });
            }}
            size="1x"
            style={styles.displayType()}
            icon={faFilter}
          />
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // ...state.themeChanger
    groceryList: state.groceryList,
  };
};

export default connect(mapStateToProps, {
  getPricesAction,
  openInteractivityModal,
})(withParams(CategoriesStatic));
