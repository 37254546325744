import * as React from "react";
import { VOLANTINO_PRIMARY } from "../../../helper/utility";
const Carosello = (props) => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.2222 2.875C15.9634 1.92625 15.1488 1.24094 14.1255 1.16734C12.9273 1.08109 11.1555 1 8.73438 1C6.32781 1 4.56344 1.08016 3.36578 1.16594C2.16719 1.25125 1.25078 2.16719 1.16547 3.36625C1.07969 4.56391 1 6.32875 1 8.73438C1 11.1555 1.08156 12.9269 1.16734 14.125C1.24094 15.1483 1.92672 15.9634 2.875 16.2217"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.38469 7.58453C5.47 6.38594 6.38594 5.47047 7.585 5.38469C8.78219 5.29844 10.547 5.21875 12.9531 5.21875C15.3592 5.21875 17.1236 5.29891 18.3213 5.38469C19.5203 5.47 20.4363 6.38594 20.5216 7.585C20.6073 8.78266 20.6875 10.5475 20.6875 12.9531C20.6875 15.3592 20.6073 17.1236 20.5216 18.3217C20.4363 19.5203 19.5203 20.4358 18.3213 20.5216C17.1236 20.6073 15.3588 20.6875 12.9531 20.6875C10.547 20.6875 8.78266 20.6073 7.58453 20.5216C6.38594 20.4363 5.47047 19.5203 5.38469 18.3213C5.29844 17.1241 5.21875 15.3592 5.21875 12.9531C5.21875 10.547 5.29891 8.78266 5.38469 7.58453Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.6152 16.6513C19.5582 15.6603 18.7965 15.0003 18.2677 14.5681C17.6405 14.0549 16.8137 13.9803 16.1382 14.4308C15.6179 14.7777 14.9035 15.3074 13.9702 16.1085C12.5827 14.7814 11.6265 13.9475 10.9993 13.4342C10.3721 12.921 9.54523 12.8469 8.86977 13.2969C8.11039 13.8031 6.93711 14.6994 5.27539 16.2585"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.5938 9.90625C14.5938 10.2792 14.7419 10.6369 15.0056 10.9006C15.2694 11.1643 15.627 11.3125 16 11.3125C16.373 11.3125 16.7306 11.1643 16.9944 10.9006C17.2581 10.6369 17.4062 10.2792 17.4062 9.90625C17.4062 9.53329 17.2581 9.1756 16.9944 8.91188C16.7306 8.64816 16.373 8.5 16 8.5C15.627 8.5 15.2694 8.64816 15.0056 8.91188C14.7419 9.1756 14.5938 9.53329 14.5938 9.90625Z"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
export default Carosello;
