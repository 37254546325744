import React from "react";
// import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GalleryModal from "./components/Gallery.modal";

const CarouselBodyMobile = ({ data, icon, type }) => {
  let imagesForGallery = [];
  //{image_file: 'https://interattivo-dev.s3.amazonaws.com/KZkWxdWu9F/products/12_RPALiAs.jpg', cropped: false, pk: 1880}
  data.images.map((el, key) => {
    return imagesForGallery.push({
      image_file: el,
      cropped: false,
      pk: key,
    });
  });

  // const listImages = images.map((element, key) => (
  //   <img
  //     onError={({ currentTarget }) => {
  //       currentTarget.onerror = null; // prevents looping
  //       currentTarget.src = `${serverResponse.config.clientIcon}`;
  //     }}
  //     key={key}
  //     src={
  //       process.env.PUBLIC_URL + element + "?v=" + serverResponse.config.version
  //     }
  //     alt="img"
  //     style={{ maxWidth: "500px" }}
  //   />
  // ));
  return (
    <div className="col-12">
      <div className="d-flex align-items-center justify-content-center mb-4 interactive-title">
        <h6 className="mb-0 ms-2">{data.title}</h6>
      </div>
      <GalleryModal autoplay={true} gallery={imagesForGallery} dots={true} />
    </div>
  );
};

export default CarouselBodyMobile;
