import * as React from "react";
const ArrowIcon = (props) => (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.5998 7.17427L8.07168 2.58052C7.8748 2.38364 7.56855 2.38364 7.37168 2.58052C7.1748 2.77739 7.1748 3.08364 7.37168 3.28052L11.0467 7.02114H1.7498C1.4873 7.02114 1.26855 7.23989 1.26855 7.50239C1.26855 7.76489 1.4873 8.00552 1.7498 8.00552H11.0904L7.37168 11.7899C7.1748 11.9868 7.1748 12.293 7.37168 12.4899C7.45918 12.5774 7.59043 12.6211 7.72168 12.6211C7.85293 12.6211 7.98418 12.5774 8.07168 12.468L12.5998 7.87427C12.7967 7.67739 12.7967 7.37114 12.5998 7.17427Z"
      fill="black"
    />
  </svg>
);
export default ArrowIcon;
