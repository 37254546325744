import React from "react";
import ReadMore from "../../common/ReadMore";
import GalleryModal from "./components/Gallery.modal";
import TitleModal from "./components/Title.modal";

const InfoBodyMobile = ({ data, icon, type }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-4 interactive-title">
        <span className="me-2">{icon}</span>
        {data.titolo ? <TitleModal title={data.titolo} /> : <h6>{type}</h6>}
      </div>
      <div
        className="col-12 h-lg-100 col-lg-6 m-auto mt-0"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6 pt-3">
        <div className="wrapText mt-lg-3">
          <ReadMore>{data.testo}</ReadMore>
        </div>
      </div>
    </>
  );
};

export default InfoBodyMobile;
