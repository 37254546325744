import axios from "axios";
import { serverResponse } from "../util/fakeServer";

const url = `${process.env.REACT_APP_ENDPOINT}/market/`;

const API = axios.create({
  baseURL: url,
  validateStatus: function (status) {
    return status < 300;
  },
});

API.interceptors.request.use(
  (req) => {
    req.headers.clientId = `${serverResponse.config.client_id}`;
    req.headers.signboardId = `${serverResponse.config.signboard_id}`;
    return req;
  },
  (err) => Promise.reject(err)
);

export const getMarket = (data) => API.post(`get`, data);

export const getSearch = (data) => API.post(`search`, data);

export const getPinMap = (data) => API.post(`get-pin-map`);

export const getDetail = (data) => API.post(`detail`, data);