import React, { useRef, useState, useEffect, useCallback } from "react";
import CustomProduct from "./CustomProduct";
import { connect } from "react-redux";
import { serverResponse } from "../../util/fakeServer";
import { BREAKPOINT, H_COMPONENTS } from "../../helper/constant";
import { isMobileSafari } from "react-device-detect";
import ReactDOM from "react-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import Loader from "../common/Loader";
import { animationType, getCustomActions } from "../../helper/utility";
import useCurrentWidth from "../../hooks/widthHook";

const CustomSlide = (props) => {
  const [loadingImages, setLoadingImages] = useState();
  const width = useCurrentWidth();
  const intervalRef = useRef(null);

  const listProducts = props.products.map((element, key) => {
    return (
      !!element.blueprint && (
        <CustomProduct
          pageNumber={props.pageNumber}
          zoom={props.zoom}
          disableSwipe={props.disableSwipe}
          enableSwipe={props.enableSwipe}
          key={key}
          blueprint={element.blueprint}
          info={getCustomActions().getInteractivity(element.item_id)}
          leafletSwipe={props.leafletSwipe}
          isSwipeDisabed={props.isSwipeDisabed}
          setSwipe={props.setSwipe}
          dynamic_price={element.dynamic_price}
          dynamic_info={element.dynamic_info}
          show_dynamic_price={element.show_dynamic_price}
          dynamic_price_position={element.dynamic_price_position}
        />
      )
    );
  });

  const imageRef = useRef(null);
  var interval = 0;
  const isImageLoad = useCallback(() => {
    var element = ReactDOM.findDOMNode(imageRef.current);

    if (element !== null && element.offsetWidth > 0) {
      clearInterval(interval);

      setLoadingImages(false);
    } else {
      setLoadingImages(true);
    }
  }, [interval]);

  useEffect(() => {
    intervalRef.current = setInterval(isImageLoad, 300);
    return () => clearInterval(intervalRef.current);
  }, [isImageLoad]);

  const safariDetectMobile = isMobileSafari ? 30 : 0;

  return (
    <div
      id={`slide_${props.pageNumber}`}
      className={`${
        serverResponse.config.scrollVertical && width < BREAKPOINT.xs
          ? "mt-2 mb-2 d-flex p-0 flex-column justify-content-center align-items-center bordo productCostomPosition"
          : `d-flex p-0 flex-column justify-content-center align-items-center bordo ${
              width > BREAKPOINT.xs && "productCostomPosition"
            }`
      } `}
    >
      {loadingImages && <Loader single={props.single} />}
      <div
        className={`${animationType(props.animationType)}`}
        style={{ position: "relative" }}
      >
        <div className={`shadow-${props.shadow}`}></div>

        <LazyLoadImage
          ref={imageRef}
          alt=""
          src={props.imagePath + "?v=" + serverResponse.config.version}
          placeholderSrc={
            props.imageThumbPath + "?v=" + serverResponse.config.version
          }
          visibleByDefault={true}
          className={`img-fluid`}
          style={{
            maxHeight:
              width < BREAKPOINT.xs && serverResponse.config.scrollVertical
                ? "unset"
                : `calc(100vh - ${
                    H_COMPONENTS.header +
                    H_COMPONENTS.pager +
                    (width < BREAKPOINT.xs ? H_COMPONENTS.bottomTab : 0) +
                    safariDetectMobile
                  }px)`,
          }}
        />
        <div className="productCostomPositionInner">{listProducts}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    carouselIndex: state.carousel,
  };
};

export default connect(mapStateToProps)(CustomSlide);
