import React from "react";
import { connect } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import GalleryModal from "./components/Gallery.modal";

const CarouselBody = ({ modalRedux }) => {
  console.log(modalRedux);
  const {
    otherProps: {
      markerInfo: {
        data: { images },
        data: { title },
      },
    },
  } = modalRedux;

  let imagesForGallery = [];

  images.map((el, key) => {
    return imagesForGallery.push({
      image_file: el,
      cropped: false,
      pk: key,
    });
  });

  // const listImages = images.map((element, key) => (
  //   <img
  //     onError={({ currentTarget }) => {
  //       currentTarget.onerror = null; // prevents looping
  //       currentTarget.src = `${serverResponse.config.clientIcon}`;
  //     }}
  //     key={key}
  //     src={
  //       process.env.PUBLIC_URL + element + "?v=" + serverResponse.config.version
  //     }
  //     alt="img"
  //     style={{ maxWidth: "500px" }}
  //   />
  // ));
  return (
    <div style={{height: 'calc(100% - 10vh)'}}>
      <h6
        className="my-2 text-center"
        style={{ fontFamily: "Rubik", fontSize: "2rem" }}
      >
        {title}
      </h6>{" "}
      <GalleryModal autoplay={true} gallery={imagesForGallery} dots={true} />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    modalRedux: state.modal,
  };
};

export default connect(mapStateToProps)(CarouselBody);
