import React from "react";
import { defaultLanguage } from "../../../helper/utility";
import { language } from "../../../languageProvider/language/language";
import ReadMore from "../../common/ReadMore";

import GalleryModal from "./components/Gallery.modal";
import ListModal from "./components/List.modal";
import TitleModal from "./components/Title.modal";

const HatChefBodyMobile = ({ data, title, icon, type }) => {
  return (
    <>
      <div className="d-flex align-items-center mb-4 interactive-title">
        <span className="me-2">{icon}</span>
        {title ? <TitleModal title={title} /> : <h6>{type}</h6>}
      </div>
      <div
        className="col-12 col-lg-6 h-lg-100"
        style={{ position: "relative" }}
      >
        <GalleryModal minImg={false} gallery={data.img} />
      </div>

      <div className="col-12 col-lg-6">
        <div
          className="overflow-lg-scroll px-15"
          style={{ height: "calc(100vh - 332px)", overflowY: "auto" }}
        >
          <div className="row">
            <div className="col-12">
              <h6
                className="mb-3"
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {language[defaultLanguage].ingredienti}
              </h6>
              <ListModal list={data.ingredients} />
            </div>
          </div>

          <div className="row">
            <div className="h-100 col-12 mt-5">
              <h6
                className="mb-3"
                style={{
                  fontWeight: "bold",
                  marginBottom: "5px",
                }}
              >
                {language[defaultLanguage].procedimento}
              </h6>
              <ReadMore limit={9999}>{data.recipe}</ReadMore>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HatChefBodyMobile;
