import * as React from "react";
const NavigaIcon = (props) => (
  <svg
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Frame">
      <path
        id="Vector"
        d="M1.5 4.6909C1.5 3.20476 2.67525 2 4.125 2C5.57475 2 6.75 3.20476 6.75 4.6909C6.75 6.29888 5.27095 7.46149 4.35246 7.9435C4.20892 8.01883 4.04108 8.01883 3.89754 7.9435C2.97905 7.46149 1.5 6.29888 1.5 4.6909ZM4.125 5.78407C4.68879 5.78407 5.14583 5.3097 5.14583 4.72453C5.14583 4.13936 4.68879 3.66499 4.125 3.66499C3.56121 3.66499 3.10417 4.13936 3.10417 4.72453C3.10417 5.3097 3.56121 5.78407 4.125 5.78407Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M11.25 13.6909C11.25 12.2047 12.4253 11 13.875 11C15.3247 11 16.5 12.2047 16.5 13.6909C16.5 15.2989 15.0209 16.4615 14.1025 16.9435C13.9589 17.0188 13.7911 17.0188 13.6475 16.9435C12.7291 16.4615 11.25 15.2989 11.25 13.6909ZM13.875 14.7841C14.4388 14.7841 14.8958 14.3097 14.8958 13.7245C14.8958 13.1394 14.4388 12.665 13.875 12.665C13.3112 12.665 12.8542 13.1394 12.8542 13.7245C12.8542 14.3097 13.3112 14.7841 13.875 14.7841Z"
        fill="white"
      />
      <path
        id="Vector_3"
        d="M8.62512 4.0625C8.31447 4.0625 8.06262 4.31434 8.06262 4.625C8.06262 4.93566 8.31447 5.1875 8.62512 5.1875H12.5976C12.7717 5.1875 12.8519 5.40407 12.7197 5.51736L5.29836 11.8785C4.37319 12.6714 4.934 14.1875 6.15253 14.1875H9.37512C9.68577 14.1875 9.93762 13.9356 9.93762 13.625C9.93762 13.3144 9.68577 13.0625 9.37512 13.0625H6.15253C5.97846 13.0625 5.89834 12.8459 6.03051 12.7326L13.4518 6.37153C14.377 5.57851 13.8162 4.0625 12.5976 4.0625H8.62512Z"
        fill="white"
      />
    </g>
  </svg>
);
export default NavigaIcon;
